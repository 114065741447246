import styled from 'styled-components'

const HostStyle = styled.div`
	--maxWidth: var(--sp175x);
	
    background-color:var(--textColor);
    color:var(--backColor);

	&.SupportUsWrapp {
		section {
			overflow: visible;
		}
		
		.SupportUsInfoWrapp {
			min-width: var(--maxWidth);
			position: relative;
			z-index: 4;
			.description {
                max-width: var(--maxWidth);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--maxWidth: 815px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--maxWidth: 670px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--maxWidth: 640px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--maxWidth: 500px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSize}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		&.SupportUsWrapp {
			min-height: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--maxWidth: 100%;

		&.SupportUsWrapp {
			min-height: 100%;
			.SupportUsInfoWrapp {
				.description {
					max-width: 85%;
				}
			}
		}
	}
`
export default HostStyle
