import React, { memo as Memo } from 'react'

//* Components
import { Text, Icon, Image } from 'components/common'

const TicketsTab = Memo((props) => {

    return (
        <div className={`tabItem ${props.tabActive ? "active" : ""}`}>
            <div className="infoItemWrapp">
                <div className="overflowHidden infoItemTitle margBottomTitle">
                    <Text tag={'p'} className={'otherElementAnimation NotoSansRegular uppercase'} text={'TicketsPerPerson'} />
                </div>

                <div className="infoItem overflowHidden">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'Adults'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.per_person + " AMD"} />
                    </div>
                    <div className="itemLine fromLeft" />
                </div>
                {
                    props.typeResidents ?
                        <>
                            <div className="infoItem overflowHidden">

                                <div className="FlexBoxContainer spaceBetween overflowHidden">
                                    <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'Children'} />
                                    <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.children + " AMD"} />
                                </div>
                                <div className="itemLine fromLeft" />
                            </div>
                            <div className="infoItem overflowHidden">

                                <div className="FlexBoxContainer spaceBetween overflowHidden">
                                    <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'RetiredAndStudents'} />
                                    <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.retired_students + " AMD"} />
                                </div>
                                <div className="itemLine fromLeft" />
                            </div>
                            <div className="FlexBox overflowHidden">
                                <Text tag={'p'} className={'pXS NotoSansRegular otherElementAnimation'} text={'TicketsFreeEveryLastSaturday'} />
                            </div>
                        </>
                        :
                        ''
                }
            </div>

            <div className="infoItemWrapp overflowHidden">
                <div className="overflowHidden infoItemTitle margBottomTitle">
                    <Text tag={'p'} className={'NotoSansRegular uppercase otherElementAnimation'} text={'GuidedTours'} />
                </div>

                <div className="infoItem overflowHidden">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'ArmenianLanguageGuide'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.armenian_language_guide + " AMD"} />
                    </div>
                    <div className="itemLine fromLeft" />
                </div>
                <div className="infoItem overflowHidden">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'ForeignLanguagesGuide'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.foreign_language_guide + " AMD"} />
                    </div>
                    <div className="itemLine fromLeft" />
                </div>
                {
                    props.data.exclusive ?
                        <div className="infoItem overflowHidden">
                            <div className="FlexBoxContainer spaceBetween overflowHidden">
                                <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'ExclusiveForeign'} />
                                <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.exclusive + " AMD"} />
                            </div>
                            <div className="otherInfoItem ">
                                <div className="FlexBoxContainer alignMiddle spaceBetween overflowHidden">
                                    <div className="FlexBoxContainer alignMiddle otherElementAnimation">
                                        <Icon className={'icon-star2 p'} />
                                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'UpPeople'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''

                }
            </div>

            <div className="infoItemWrapp overflowHidden">
                <div className="overflowHidden infoItemTitle margBottomTitle">
                    <Text tag={'p'} className={'NotoSansRegular uppercase otherElementAnimation'} text={'AudioGidePerPerson'} />
                </div>

                <div className="infoItem overflowHidden">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'Armenian'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.armenian + " AMD"} />
                    </div>
                    <div className="itemLine fromLeft" />
                </div>

                <div className="infoItem overflowHidden">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'ForeignLanguages'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.foreign_languages + " AMD"} />
                    </div>
                    <div className="otherInfoItem ">
                        <div className="FlexBoxContainer alignMiddle spaceBetween overflowHidden">
                            <div className="FlexBoxContainer alignMiddle otherElementAnimation">
                                <Icon className={'icon-star2 p'} />
                                <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'GroupsPeople'} />
                            </div>
                            <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.groups_lt_15 + " AMD"} />
                        </div>
                        <div className="FlexBoxContainer alignMiddle spaceBetween overflowHidden">
                            <div className="FlexBoxContainer alignMiddle otherElementAnimation">
                                <Icon className={'icon-star2 p'} />
                                <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'GroupsMorePeople'} />
                            </div>
                            <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.groups_gt_15 + " AMD"} />
                        </div>
                    </div>
                    <div className="itemLine fromLeft" />
                    <div className="FlexBoxContainer spaceBetween overflowHidden" />
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pXS NotoSansRegular otherElementAnimation'} text={'AvailableLanguages'} />
                        <div className="itemAvailableLanguages FlexBoxContainer alignMiddle">
                            {
                                props?.data?.flags ?
                                    props.data.flags.map((i, k) => {
                                        return (
                                            <Image key={k} src={i} alt='flag' />
                                        )
                                    })
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                props.typeResidents ?
                    <div className="infoItemWrapp">
                        <div className="overflowHidden infoItemTitle margBottomTitle">
                            <Text tag={'p'} className={'NotoSansRegular uppercase otherElementAnimation'} text={'AcademicProgramTickets'} />
                        </div>
                        <div className="infoItem">
                            <div className="FlexBoxContainer spaceBetween overflowHidden">
                                <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'EducationVisits'} />
                                <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.education_visits + " AMD"} />
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }

            <div className="infoItemWrapp">
                <div className="overflowHidden infoItemTitle margBottomTitle">
                    <Text tag={'p'} className={'NotoSansRegular uppercase otherElementAnimation'} text={'FilmViewing'} />
                </div>
                <div className="infoItem">
                    <div className="FlexBoxContainer spaceBetween overflowHidden">
                        <Text tag={'p'} className={'pM NotoSansRegular otherElementAnimation'} text={'FilmViewing'} />
                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.data.film_viewing + " AMD"} />
                    </div>
                    <div className="FlexBox overflowHidden">
                        <Text tag={'p'} className={'pXS NotoSansRegular otherElementAnimation'} text={'PleaseFilmViewingAvailable'} />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TicketsTab