import React, { memo as Memo, useEffect, useRef, useState, useCallback } from 'react'
import { Image, Cursor, Container } from 'components/common'

//* HOC's
import { withUIContext } from 'context';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'

import SwiperCore, {
    EffectFade, Navigation, Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination]);

//* Style
import SliderStyle from './style'

const Slider = Memo(({ winHeight, winWidth, ...props }) => {
    const contRef = useRef();
    const slRef = useRef();

    const [activeClass, setActiveClass] = useState('');

    useEffect(() => {

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (contRef?.current[0]?.getBoundingClientRect().y < winHeight * 0.7) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    const clickHandler = useCallback((e) => {
        if (e.clientX < winWidth / 2) {
            slRef.current.swiper.slidePrev()
        } else {
            slRef.current.swiper.slideNext()
        }
    }, [winWidth])


    return (
        <Cursor text={'next'} reverse={true}>
            <SliderStyle className='FlexBoxColumn slider'>
                <Container ref={contRef} className={`containerPaddTopBottom ${activeClass}`} onClick={(e) => clickHandler(e)}>
                    <Swiper
                        ref={slRef}
                        spaceBetween={30}
                        speed={300}
                        effect={'fade'}
                        navigation={true}
                        pagination={{
                            "type": "progressbar"
                        }}
                        className='mySwiper'
                    >

                        {
                            props.data.map((item, ind) => {
                                return (
                                    <SwiperSlide key={ind}>
                                        <Image src={item} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Container>
            </SliderStyle>
        </Cursor>
    )
})

export default withUIContext(Slider, ['winHeight', 'winWidth'])
