import styled from 'styled-components'


const ImgTextCompStyle = styled.div.attrs(props => ({ className: props.className }))`
    background-color: #BFC1D3;
    color:var(--backColor);

    &.landscape {
        background-color: transparent;
        color: inherit;
    }

    section {
        .imageCont {
            width: 45%;
            padding-top: 56.7%; 

            img {
                transition: var(--trTime);
                clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
            }

            &.active {
                img {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                }
            }
            
            &.landscape {
                padding-top: 30.4%;

                img {
                    object-fit: contain !important;
                    object-position: top !important;
                }
            }
        }
        
        .info {
            margin-left:var(--sp16x);
            flex:1;

            .text-cont {
                max-width: var(--sp98x);
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
            props.theme.mediaQuery.DesktopSizeM}) {
        
        section {
            /* .imageCont {
                width: 45%;
                padding-top: 56.7%; 

                &.landscape {
                    padding-top: 30.4%;
                }
            } */
            
            .info {
                margin-left:var(--sp14x);

                .text-cont {
                    max-width: var(--sp72x);
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
            props.theme.mediaQuery.DesktopSizeS}) {

        section {
            
            .info {
                margin-left:var(--sp6x);

                .text-cont {
                    max-width: var(--sp67x);
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
            props.theme.mediaQuery.DesktopSizeXS}) {

        section {
            
            .info {
                margin-left:var(--sp5x);

                .text-cont {
                    max-width: var(--sp60x);
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
            props.theme.mediaQuery.LaptopSize}) {

        section {
            
            .info {
                margin-left:var(--sp2x);

                .text-cont {
                    max-width: unset;
                }
            }
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
            props.theme.mediaQuery.TabletSize}) {

        section {
            flex-direction: column;

            .imageCont {
                width: 76%;
                padding-top: 95.7%;

                &.landscape {
                    width: 100%;
                    padding-top: 67.3%;

                    img {
                        object-fit: cover !important;
                    }
                }
            }
            
            .info {
                margin-left:0;
                margin-top: var(--sp3x);

                .text-cont {
                    max-width: unset;
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {

        section {
            flex-direction: column;

            .imageCont {
                width: 100%;
                padding-top: 126.1%;

                &.landscape {
                    width: 100%;
                    padding-top: 67.3%;

                    img {
                        object-fit: cover !important;
                    }
                }
            }
            
            .info {
                margin-left:0;
                margin-top: var(--sp3x);

                .text-cont {
                    max-width: unset;
                }
            }
        }
    }
`

export default ImgTextCompStyle