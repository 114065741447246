import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Text, AnimationWrapp } from 'components/common'

//* Config
import { arrayGen } from 'helpers'


const DonateViaBankSection = Memo(({ selectedLang, text }) => {

    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const titleRef = useRef()
    const textRef = useRef()

    useEffect(() => {
        if (
            text && titleRef?.current && textRef?.current
        ) {
            setSecObj([
                arrayGen([titleRef.current, textRef.current])
            ])
        }

    }, [titleRef, textRef, selectedLang])


    return (
        <AnimationWrapp state={secObj} >
            <Container className="containerPaddBottom containerPaddTop">
                <div className="FlexBoxContainer overflowHidden margBottomTitle">
                    <Text ref={titleRef} tag={'h4'} className={'singleTitle GHEAMariamBold uppercase'} text={'DonateViaBank'} />
                </div>
                <div className="overflowHidden">
                    <Text ref={textRef} tag={'p'} className={'description pL NotoSansRegular'} text={text} />
                </div>
            </Container>
        </AnimationWrapp>
    )
})

export default withLanguageContext(DonateViaBankSection, ['selectedLang'])
