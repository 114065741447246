import styled from 'styled-components'

const FilterBarStyle = styled.div.attrs()`

  .filter-btns {
    margin: var(--sp13x) 0 0;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > div {
      margin-left: var(--sp4x);
      margin-bottom: var(--sp7x);
      margin-top: 0;
    }

    .filter-btn {
      &.active {
        color: var(--backColor);
        background-color: var(--textColor);
      }
    }
  }


  @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
    .filter-btns {
      margin: var(--sp10x) 0 0;

      & > div {
        margin-left:var(--sp3x);
        margin-bottom: var(--sp6x);
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
    .filter-btns {
      margin: var(--sp9x) 0 0;

      & > div {
        margin-left:var(--sp3x);
        margin-bottom: var(--sp5x);
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}){
    .filter-btns {
      margin: var(--sp8x) 0 0;

      & > div {
        margin-left:var(--sp3x);
        margin-bottom: var(--sp4x);
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
    .filter-btns {
      margin: var(--sp7x) 0 0;

      & > div {
        margin-left:var(--sp3x);
        margin-bottom: var(--sp3x);
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
    .filter-btns {
      margin: var(--sp8x) 0 0;

      & > div {
        margin-left:var(--sp3x);
        margin-bottom: var(--sp3x);
      }
    }
  }
`
export default FilterBarStyle
