import React, { memo as Memo, useCallback, useMemo, useState } from 'react'

//* HOCs
import { withLanguageContext } from 'context'

//* Components
import { CustomLink, FormContainer, FormInput, FormItem, LottieAnimation, Text, Icon } from 'components/common'

//* Components
import { Button, Checkbox, Select } from 'antd'

//* Helpers
import { config } from 'helpers'

//* Styles
import FormStyle from 'styles/pages/FormStyle'

import BtnLottie from 'lottieAnimation/btnAnimation.json'


const CheckoutForm = Memo(({ regions, countries, selectedLang, ...props }) => {

    //! States
    const [selectedRegion, setSelectedRegion] = useState()
    const [playState, setPlaySate] = useState('')
    const [passBtnIsActive, setPassBtnIsActive] = useState(false)


    //! Selected Region Countries
    const regionCountries = useMemo(() => {
        return countries?.[selectedRegion]?.map(i => (
            <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
        ))
    }, [selectedRegion])


    //! Region On Change
    const regionOnChange = useCallback((regionId) => setSelectedRegion(regionId), [])


    //! Terms Toggle Function
    const acceptTermsChange = useCallback((e) => {
        setPassBtnIsActive(e.target.checked)
    }, [])

    return (
        <FormStyle className='right'>
            <FormContainer
                layout={'vertical'}
                onFinish={props.onFinish}
                onChange={props.onChange}
                initialValues={{ plan: true }}
                className={'FormRow FlexBoxContainer spaceBetween'}>

                <div className='FormCol overflowHidden'>
                    <Text tag={'p'} className={'title uppercase pL GHEAMariamBold margBottomTitle'}
                        text={'ContactInformation'} />

                    <FormItem className="otherElementAnimation" name={'email'} validationType={'email'} required={true}>
                        <FormInput type='email' placeholder={props.translate('EmailAddress') + '*'} />
                    </FormItem>
                </div>

                <Text tag={'p'} className={'title ship-title uppercase pL GHEAMariamBold margBottomTitle'}
                    text={'ShippingAddress'} />

                <div className='shipping-address FlexBoxContainer spaceBetween'>
                    <div className='FormCol FormCol-50 overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'name'} required={true}>
                            <FormInput placeholder={props.translate('Name') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50 overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'surname'} required={true}>
                            <FormInput placeholder={props.translate('Surname') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'address'} required={true}>
                            <FormInput placeholder={props.translate('Address') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'apartment'} required={true}>
                            <FormInput placeholder={props.translate('Apartment') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'city'} required={true}>
                            <FormInput placeholder={props.translate('City') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol overflowHidden'>
                        <FormItem className="otherElementAnimation" validationType={'phone'} name={'phone'} required={true}>
                            <FormInput disableKeys={['-', 'e']} type={'number'} placeholder={props.translate('PhoneNumber') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-30 overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'region_id'} required={true}>
                            <Select placeholder={props.translate('Region') + '*'} onChange={regionOnChange} suffixIcon={<Icon className='icon-arrow-down' />}>
                                {regions.map(region => (
                                    <Select.Option key={region.id} value={region.id}>
                                        {region.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-30 overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'country_id'} required={true}>
                            <Select placeholder={props.translate('Country') + '*'} suffixIcon={<Icon className=' icon-arrow-down' />}>
                                {regionCountries}
                            </Select>
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-30 overflowHidden'>
                        <FormItem className="otherElementAnimation" name={'post_code'} required={true}>
                            <FormInput placeholder={props.translate('PostCode') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol margBottomTitle overflowHidden'>
                        <FormItem className="otherElementAnimation checkBox" valuePropName='checked'>
                            <Checkbox onChange={acceptTermsChange}>
                                {props.translate('HaveReadAgreed')}
                                &nbsp;
                                <CustomLink
                                    url={config.routes.terms.path}
                                    className={'black underline MonMedium'}
                                    options={{ target: '_blank' }}
                                >
                                    {props.translate('TermsConditions')}
                                </CustomLink>
                            </Checkbox>
                        </FormItem>
                    </div>

                    <div className='btn-cont FlexBox justifyEnd overflowHidden'>
                        <Button htmlType={'Submit'}
                            className={`btn otherElementAnimation NotoSansRegular btnAnimation pM uppercase ${!passBtnIsActive && 'disabled'}`}
                            onMouseEnter={() => setPlaySate(true)}
                            onMouseOut={() => setPlaySate(false)}>
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState}
                                autoplay={false} loop={false} />
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState}
                                autoplay={false} loop={false} />
                            {props.translate('ContinueToPayment')}
                        </Button>
                    </div>
                </div>
            </FormContainer>
        </FormStyle>
    )
})

export default withLanguageContext(CheckoutForm, ['selectedLang', 'translate'])