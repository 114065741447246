import styled from 'styled-components'

const SupportStyle = styled.div`
	--maxWidth: var(--sp125x);

	&.SupportUsWrapp {
		min-height: calc((100vw / 1.6) - (var(--containerMarginBottom) * 2));
		.SupportUsInfoWrapp {
			max-width: var(--maxWidth);
			position: relative;
			z-index: 6;
			.description {
				max-width: 85%;
			}
		}
		.textSvgWrapper {
			pointer-events: none;
			position: absolute;
			top: -3%;
			left: 0;
			right: 0;
			bottom: -3%;
			z-index: 0;
			svg {
				width: 100%;
				height: 100%;
			}
			textPath {
				font-size: var(--sp6x);
				fill: var(--textColor);
				font-family: GHEAMariamRegular;
				text-transform: uppercase;
				opacity: 0.26;
			}
			path {
				fill: none;
			}
		}
		.imagesWrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 5;
			transform: rotateY(270deg);
			.imagesCont {
				position: relative;
				border-radius: 100%;
				border: 1px solid rgb(220 240 207 / 26%);
				margin: 0 auto;
				width: 98%;
				height: 100%;
				transform: rotate(-9deg);
				.imageCont {
					padding: 4%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 12;
					transform: translateX(-50%) translateY(-50%);
					.image {
						object-fit: contain;
					}
                    
					> span {
						transition: all 0.2s ease-in-out;
						transform: scale(0);
						opacity: 0;
						transition-delay: 0.6s;
					}
					&:hover {
						> span {
							transform: scale(1);
							opacity: 1;
							transition: none;
						}
					}
				}
				.image1 {
					top: 50%;
					left: 0%;
				}
				.image2 {
					top: 39%;
					left: 0%;
				}
				.image3 {
					top: 28%;
					left: 4%;
				}
				.image4 {
					top: 19%;
					left: 9%;
				}
				.image5 {
					top: 16%;
					left: 13%;
				}
				.image6 {
					top: 10%;
					left: 19%;
				}
				.image7 {
					top: 6%;
					left: 25%;
				}
				.image8 {
					top: 4%;
					left: 32%;
				}
				.image9 {
					top: 0%;
					left: 39%;
				}
				.image10 {
					top: 0%;
					left: 46%;
				}
				.image11 {
					top: 0%;
					left: 53%;
				}
				.image12 {
					top: 0%;
					left: 60%;
				}
				.image13 {
					top: 3%;
					left: 66%;
				}
				.image14 {
					top: 6%;
					left: 73%;
				}
				.image15 {
					top: 9%;
					left: 80%;
				}
				.image16 {
					top: 14%;
					left: 86%;
				}
				.image17 {
					top: 20%;
					left: 92%;
				}
				.image18 {
					top: 33%;
					left: 96%;
				}
				.image19 {
					top: 43%;
					left: 99%;
				}
				.image20 {
					top: 55%;
					left: 99%;
				}
				.image21 {
					top: 66%;
					left: 96.5%;
				}
				.image22 {
					top: 78%;
					left: 92%;
				}
				.image23 {
					top: 85%;
					left: 85%;
				}
				.image24 {
					top: 90%;
					left: 78%;
				}
				.image25 {
					top: 94%;
					left: 73%;
				}
				.image26 {
					top: 95%;
					left: 67%;
				}
				.image27 {
					top: 98%;
					left: 62%;
				}
				.image28 {
					top: 100%;
					left: 55%;
				}
				.image29 {
					top: 100%;
					left: 49%;
				}
				.image30 {
					top: 98%;
					left: 43%;
				}
				.image31 {
					top: 96%;
					left: 37%;
				}
				.image32 {
					top: 94%;
					left: 32%;
				}
				.image33 {
					top: 94%;
					left: 26%;
				}
				.image34 {
					top: 89%;
					left: 19%;
				}
				.image35 {
					top: 82%;
					left: 12%;
				}
				.image36 {
					top: 75%;
					left: 10%;
				}
				.image37 {
					top: 70%;
					left: 5%;
				}
				.image38 {
					top: 64%;
					left: 3%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--maxWidth: var(--sp101x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--maxWidth: var(--sp84x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--maxWidth: var(--sp80x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--maxWidth: var(--sp62x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSize}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		&.SupportUsWrapp {
			min-height: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--maxWidth: 100%;

		.fontSize32 {
			font-size: var(--sp4x);
		}

		&.SupportUsWrapp {
			min-height: 100%;
			.SupportUsInfoWrapp {
				.description {
					max-width: 85%;
				}
			}
			.textSvgWrapper {
				left: -40%;
				right: -40%;
			}
			.imagesWrapper {
				left: -40%;
				right: -40%;
			}
		}
	}
`
export default SupportStyle
