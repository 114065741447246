import React, { memo as Memo, useEffect, useRef, useState, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Helpers
import { formatNumber, arrayGenChild, config } from 'helpers'

//* Components
import { Container, Text, AnimationWrapp, FormItem, FormContainer, FormInput, LottieAnimation, CustomLink } from 'components/common'
import { Button, Checkbox, Input, Radio } from 'antd'

//* Styles
import FormStyle from 'styles/pages/FormStyle'

import BtnLottie from 'lottieAnimation/btnAnimation.json'

const DonateOnlineSection = Memo(({ selectedLang, ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [playState, setPlaySate] = useState('')
	const [anonymousState, setAnonymousState] = useState(false)
	const [donationResult, setDonationResult] = useState(2500)
	const [otherDonate, setOtherDonate] = useState(0)
	const [passBtnIsActive, setPassBtnIsActive] = useState(false)

	//! Refs
	const titleRef = useRef()
	const radioGroupTitleRef = useRef()
	const radioGroupRef = useRef()
	const inputGroupRef = useRef()
	const formRef = useRef()

	useEffect(() => {
		if (titleRef?.current && radioGroupRef?.current && radioGroupTitleRef?.current && inputGroupRef?.current) {
			setSecObj([
				titleRef.current,
				radioGroupTitleRef.current,
				arrayGenChild(radioGroupRef.current.childNodes),
				arrayGenChild(inputGroupRef.current.childNodes),
			])
		}
	}, [titleRef, radioGroupRef, radioGroupRef, inputGroupRef, selectedLang, anonymousState])

	//! Checkbox On Change
	const checkboxOnChange = useCallback(() => {
		setAnonymousState(!anonymousState)
	}, [anonymousState])

	//! Checkbox On Change
	const radioOnChange = useCallback(
		(e) => {
			setDonationResult(e.target.value)
		},
		[donationResult]
	)

	const otherDonateOnChange = useCallback(
		(e) => {
			setOtherDonate(e.target.value)
		},
		[otherDonate]
	)

	const currentRadioChange = useCallback(() => {
		document.querySelector('#inpDod').focus()
	})

	//! Terms Toggle Function
	const acceptTermsChange = useCallback((e) => {
		setPassBtnIsActive(e.target.checked)
	}, [])

	return (
		<AnimationWrapp state={secObj} duration={0.2} stagger={0}>
			<Container className='containerPaddBottom containerPaddTop'>
				<div className='FlexBoxContainer overflowHidden margBottomTitle'>
					<Text ref={titleRef} tag={'h4'} className={'singleTitle GHEAMariamBold uppercase'} text={'DonateOnline'} />
				</div>

				<FormStyle>
					<div className='SignInFormWrapp'>
						<FormContainer
							ref={formRef}
							layout={'vertical'}
							onFinish={props.onClick}
							initialValues={{ plan: true, amount: donationResult }}
							className={'FormRow FlexBoxContainer spaceBetween'}
						>
							<div className='FormColGroup FlexBoxContainer spaceBetween overflowHidden'>
								<div className='FlexBoxContainer overflowHidden margBottomTitle'>
									<Text
										ref={radioGroupTitleRef}
										className={'otherElementAnimation NotoSansRegular uppercase'}
										text={'ChooseDonationAmount'}
									/>
								</div>

								<FormItem validationType='radio' name={'amount'} required={true}>
									<Radio.Group onChange={radioOnChange}>
										<div className='FlexBoxContainer alignMiddle' ref={radioGroupRef}>
											<div className='colItems overflowHidden'>
												<Radio.Button value={2500}>{'2.500 AMD'}</Radio.Button>
											</div>
											<div className='colItems overflowHidden'>
												<Radio.Button value={10000}>{'10.000 AMD'}</Radio.Button>
											</div>
											<div className='colItems overflowHidden'>
												<Radio.Button value={50000}>{'50.000 AMD'}</Radio.Button>
											</div>
											<div className='colItems overflowHidden'>
												<Radio.Button value={100000}>{'100.000 AMD'}</Radio.Button>
											</div>
											<div className='colItems overflowHidden'>
												<Radio.Button value={500000}>{'500.000 AMD'}</Radio.Button>
											</div>
											<div className='colItems overflowHidden other'>
												<Radio.Button value={'other'} onChange={currentRadioChange}>
													<div className='mutableFormItem'>
														<FormInput
															disableKeys={['-']}
															min={0}
															type={'number'}
															id={'inpDod'}
															onChange={otherDonateOnChange}
															placeholder={props.translate('Enter') + '*'}
														/>
													</div>
												</Radio.Button>
											</div>
										</div>
									</Radio.Group>
								</FormItem>
							</div>

							<div className='FlexBoxContainer spaceBetween' ref={inputGroupRef}>
								<div className='FlexBoxContainer overflowHidden margBottomTitle'>
									<Text className={'otherElementAnimation NotoSansRegular uppercase'} text={'ContactDetails'} />
								</div>

								<div className='FormCol overflowHidden'>
									<FormItem className='otherElementAnimation' valuePropName='checked'>
										<Checkbox checked={anonymousState} onChange={checkboxOnChange}>
											{props.translate('Anonymous')}
										</Checkbox>
									</FormItem>
								</div>

								{!anonymousState && (
									<>
										<div className='FormCol FormCol-50 overflowHidden'>
											<FormItem className='otherElementAnimation' name={'name'} required={true}>
												<FormInput placeholder={props.translate('Name') + (anonymousState ? '' : '*')} />
											</FormItem>
										</div>
										<div className='FormCol FormCol-50 overflowHidden'>
											<FormItem className='otherElementAnimation' name={'surname'} required={true}>
												<FormInput placeholder={props.translate('Surname') + (anonymousState ? '' : '*')} />
											</FormItem>
										</div>

										<div className='FormCol FormCol-50 overflowHidden'>
											<FormItem className='otherElementAnimation' validationType={'email'} name={'email'} required={true}>
												<FormInput placeholder={props.translate('EmailAddress') + (anonymousState ? '' : '*')} />
											</FormItem>
										</div>
										<div className='FormCol FormCol-50 overflowHidden'>
											<FormItem className='otherElementAnimation' validationType={'phone'} name={'phone'} required={true}>
												<FormInput
													disableKeys={['-', 'e']}
													type={'number'}
													min={0}
													placeholder={props.translate('PhoneNumber') + (anonymousState ? '' : '*')}
												/>
											</FormItem>
										</div>

										<div className='FormCol overflowHidden'>
											<FormItem className='otherElementAnimation' name={'organizationFondName'} required={false}>
												<FormInput placeholder={props.translate('OrganizationOrFondName')} />
											</FormItem>
										</div>

										<div className='FormCol marginNone overflowHidden'>
											<FormItem className='otherElementAnimation' name={'message'}>
												<Input.TextArea placeholder={props.translate('Message')} />
											</FormItem>
										</div>
									</>
								)}

								<div className='FormCol FlexBoxContainer spaceBetween totalWrapp overflowHidden'>
									<div className='otherElementAnimation line' />
									<Text className={'NotoSansRegular uppercase otherElementAnimation'} text={'Total'} />
									<Text
										className={'NotoSansRegular uppercase otherElementAnimation'}
										text={donationResult !== 'other' ? formatNumber(donationResult) + 'AMD' : formatNumber(otherDonate) + 'AMD'}
									/>
								</div>

								<div className='FormCol margBottomTitle overflowHidden'>
									<FormItem className='otherElementAnimation checkBox' valuePropName='checked'>
										<Checkbox onChange={acceptTermsChange}>
											{props.translate('HaveReadAgreed')}
											&nbsp;
											<CustomLink
												url={config.routes.terms.path}
												className={'black underline MonMedium'}
												options={{ target: '_blank' }}
											>
												{props.translate('TermsConditions')}
											</CustomLink>
										</Checkbox>
									</FormItem>
								</div>

								<div className='btn-cont FlexBox justifyEnd overflowHidden'>
									<Button
										htmlType={'Submit'}
										className={`btn otherElementAnimation NotoSansRegular btnAnimation pM uppercase ${
											passBtnIsActive && (donationResult === 'other' ? otherDonate > 0 : donationResult) ? '' : 'disabled'
										}`}
										onMouseEnter={() => setPlaySate(true)}
										onMouseOut={() => setPlaySate(false)}
									>
										<LottieAnimation
											animData={BtnLottie}
											isPlay={playState}
											isReverse={!playState}
											autoplay={false}
											loop={false}
										/>
										<LottieAnimation
											animData={BtnLottie}
											isPlay={playState}
											isReverse={!playState}
											autoplay={false}
											loop={false}
										/>
										{props.translate('ContinueToPayment')}
									</Button>
								</div>
							</div>
						</FormContainer>
					</div>
				</FormStyle>
			</Container>
		</AnimationWrapp>
	)
})

export default withLanguageContext(DonateOnlineSection, ['selectedLang', 'translate'])
