import React, { memo as Memo, useRef, useEffect, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { AnimationWrapp, Image, Text, Container } from 'components/common'

//* Helpers
import { isWebPSupport } from 'helpers'

//* Style
import AboutStyle from './style'

const AboutSection = Memo(({ selectedLang, ...props }) => {

    //! States
    const [secObj, setSecObj] = useState()
    const [secTopObj, setSecTopObj] = useState()
    const [startAnimation, setStartAnimation] = useState(false)

    //! Refs
    const titleRef = useRef()
    const textRef = useRef()
    // const btnRef = useRef()
    const contRef = useRef()

    useEffect(() => {
        if (
            titleRef?.current && textRef?.current && contRef?.current
        ) {
            setSecTopObj(contRef.current[0])
            setSecObj([[titleRef.current, textRef.current]])
        }
    }, [titleRef, textRef, textRef, selectedLang])

    const StartAnim = (x) => {
        (x && !startAnimation) ? setStartAnimation(true) : setStartAnimation(false)
    }

    return (
        <AnimationWrapp state={secObj} sectionTop={secTopObj} sectionAnimationStart={e => StartAnim(e)}>
            <Container className='relativeWrapp overflowHidden containerPaddTopBottom' ref={contRef}>
                <AboutStyle className='AboutInfoWrapp FlexBoxColumn alignCenter'>
                    <Text ref={titleRef} tag={'h4'} className={'otherElementAnimation GHEAMariamRegular margBottomTitle'} text={props.aboutTitle} />

                    <Text ref={textRef} tag={'p'} className={'description pL NotoSansRegular'} text={props.aboutText} />

                    {/* <div ref={btnRef}>
						<Button text={'LearnMore'} link={config.routes.aboutMuseum.path} btnAnimation={true} wrappClassName={"otherElementAnimation"} />
					</div> */}

                    <Image className={`AboutParajanovBackImage ${startAnimation ? 'active' : ''} ${typeof window !== undefined || isWebPSupport() ? 'webp' : 'no-webp'}`} src={'/images/AboutParajanov/AboutParajanovImage.jpg'} alt={'Parajanov image'} />
                </AboutStyle>
            </Container>
        </AnimationWrapp>
    )
})

export default withLanguageContext(AboutSection, ['selectedLang'])
