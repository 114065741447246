import styled from 'styled-components'

const AboutStyle = styled.div`
	--maxWidthWrapp: 1150px;
	--maxWidthDescription: 86%;

	max-width: var(--maxWidthWrapp);
	min-height: 100vh;

	.description {
		max-width: var(--maxWidthDescription);
	}

	@keyframes ink-transition {
		0% {
			transform: translateX(0%);
		}
		100% {
			transform: translateX(-100%);
		}
	}

	.AboutParajanovBackImage {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background: linear-gradient(to right, #272926 24%, #0000);
		}

        &.webp:before {
            background-image: url('/images/AboutParajanov/watercolor.webp');
        }

        &.no-webp:before {
            background-image: url('/images/AboutParajanov/watercolor.png');
        }

		&::before {
			background-size: 100% 102%;
			content: '';
			height: 101%;
			left: 0%;
			position: absolute;
			z-index: 2;
			top: -1%;
			transform: translateX(0%);
			width: 3000%;
		}

		.image {
			object-position: 66%;
		}

		&.active::before {
			animation: ink-transition 1s steps(26) forwards;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--maxWidthWrapp: 1035px;
		--maxWidthDescription: 71%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--maxWidthWrapp: 800px;
		--maxWidthDescription: 69%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--maxWidthWrapp: 715px;
		--maxWidthDescription: 78%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--marginBottomTitle: var(--sp3x);
		--maxWidthWrapp: 520px;
		--maxWidthDescription: 83%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--maxWidthWrapp: 100%;
		--maxWidthDescription: 100%;
		min-height: auto;
	}
`
export default AboutStyle
