import React, { memo as Memo, useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Image, Text } from 'components/common'

//* Config
import { config, randomIntFromInterval } from 'helpers'

//* HOC's
import { withUIContext } from 'context'
import { useRouter } from 'next/router'

//!Styles
import SingleProductStyle from './style'

const SingleProduct = Memo(({ winHeight, product, ...props }) => {
    const ref = useRef()
    const [activeClass, setActiveClass] = useState('')
    const router = useRouter()

    useEffect(() => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.7) {
            setTimeout(() => {
                setActiveClass('active')
            }, 300)
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.7) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    const storeLoadImg = useMemo(() => {
        return `/images/LoadImgs/${randomIntFromInterval(1, 11)}.png`
    }, [])

    const onItemClick = useCallback(() => {
        props.onClick && props.onClick()
        router.push(config.routes.shop.path + '/' + product.slug)
    }, [config.routes.shop.path, product.slug, props.onClick])

    return (
        <SingleProductStyle ref={ref} className={`FlexBoxColumn ${activeClass}`}>
            <div className={`FlexBoxColumn alignMiddle link`} onClick={onItemClick}>
                <div className={'info FlexBox spaceBetween'}>
                    <Text tag={'p'} className={'name pL NotoSansRegular'} text={product.name} />
                    <div className='FlexBox'>
                        <Text tag={'p'} className={'price p NotoSansRegular'} text={product.price} />
                        &nbsp;
                        <Text tag={'p'} className={'price p NotoSansRegular'} text={'amd'} />
                    </div>
                </div>
                <div className='images'>
                    <Image className='load-img' src={storeLoadImg} />
                    <Image className='image-prod' src={product.featured_image} />
                </div>
            </div>
        </SingleProductStyle>
    )
})

export default withUIContext(SingleProduct, ['winHeight'])
