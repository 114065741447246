import React, { memo as Memo, useEffect, useRef, useState, useMemo } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Config
import { config, arrayGen } from 'helpers'

//* Components
import { Text, AnimationWrapp, Container, Button, CustomLink, Icon } from 'components/common'
import { NewsItem } from 'components/pages'


//* Style
import NewsStyle from './style'

const NewsSection = Memo(({ winWidth, screenSizes, selectedLang, translate, data }) => {
    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const contRef = useRef()
    const itemsRef = useRef([]);
    const titleRef = useRef([]);
    const descriptionRef = useRef([]);
    const dateRef = useRef([]);
    const iconRotateRef = useRef([]);

    useEffect(() => {
        if (
            data && winWidth >= 1280 && contRef?.current && iconRotateRef?.current && itemsRef?.current && titleRef?.current && descriptionRef?.current && dateRef?.current
        ) {
            setSecObj([
                contRef.current[0].childNodes[0].firstChild,
                itemsRef.current,
                iconRotateRef.current,
                arrayGen([titleRef.current, descriptionRef.current, dateRef.current]),
                contRef.current[0].childNodes[2].firstChild,
            ])
        }

        else if (
            data && winWidth < 1280 && winWidth >= 768 && contRef?.current && itemsRef?.current && iconRotateRef?.current && titleRef?.current && descriptionRef?.current && dateRef?.current
        ) {
            setSecObj([
                contRef.current[0].childNodes[0].firstChild,
                itemsRef.current,
                iconRotateRef.current,
                arrayGen([titleRef.current, descriptionRef.current, dateRef.current]),
                contRef.current[0].childNodes[2].firstChild,
            ])
        }
        else if (
            data && winWidth < 768 && contRef?.current && itemsRef?.current && titleRef?.current && descriptionRef?.current && dateRef?.current
        ) {
            setSecObj([
                contRef.current[0].childNodes[0].firstChild,
                itemsRef.current,
                arrayGen([titleRef.current, descriptionRef.current, dateRef.current]),
                contRef.current[0].childNodes[2].firstChild,
            ])
        }
    }, [data, contRef, titleRef, descriptionRef, dateRef, winWidth, selectedLang])


    const storeNews = useMemo(() => {
        if (winWidth >= 1280) {
            return (
                data.slice(0, 3).map((i, k) => {
                    return (
                        <div className="itemWrap relativeWrapp" key={k}>
                            <div className="otherElementWrapp" ref={el => iconRotateRef.current[k] = el}>
                                <div className="otherElement FlexBox alignMiddle alignCenter">
                                    <Icon className="icon-starIconTitle" />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 490.103 323.007" preserveAspectRatio="none">
                                        <path d="M288.536,323.007h0A336.056,336.056,0,0,0,270.09,214.076a335.937,335.937,0,0,0-21.9-49.754,338.2,338.2,0,0,0-29.311-45.155,339.768,339.768,0,0,0-35.952-39.784,339.4,339.4,0,0,0-41.818-33.639A337.381,337.381,0,0,0,94.2,19.022,335.418,335.418,0,0,0,42.978,0h490.1A335.515,335.515,0,0,0,288.535,323.006Z" transform="translate(-42.978)" fill="none" />
                                        <path d="M288.489,295.291a335.421,335.421,0,0,1,68.475-177.246A337.863,337.863,0,0,1,481.563,17.9,335.729,335.729,0,0,1,524.918,1.178H51.155A336.788,336.788,0,0,1,94.7,17.955a338.545,338.545,0,0,1,47.074,26.81A340.621,340.621,0,0,1,183.74,78.521a340.983,340.983,0,0,1,36.076,39.923,339.337,339.337,0,0,1,29.414,45.314,337.273,337.273,0,0,1,21.973,49.93,336.081,336.081,0,0,1,17.286,81.6m.047,27.716h0A336.064,336.064,0,0,0,270.09,214.072a336.05,336.05,0,0,0-21.9-49.754,338.329,338.329,0,0,0-29.311-45.156,339.833,339.833,0,0,0-35.952-39.785,339.4,339.4,0,0,0-41.818-33.638A337.405,337.405,0,0,0,94.2,19.022,335.431,335.431,0,0,0,42.978,0h490.1a334.108,334.108,0,0,0-97.735,45.6A337.1,337.1,0,0,0,328.709,163.753a333.883,333.883,0,0,0-40.173,159.254Z" transform="translate(-42.978 0)" />
                                    </svg>
                                </div>
                            </div>

                            <CustomLink url={config.routes.news.path + "/" + i.slug} className={"linkWrap FlexBoxColumn spaceBetween otherElementAnimation"} ref={el => itemsRef.current[k] = el} >
                                <div>
                                    <div className="iconWrap textAlignCenter">
                                        <Icon className="icon-arrowRight" />
                                    </div>
                                    <div className="overflowHidden" >
                                        <Text className={'otherElementAnimation pL GHEAMariamBold uppercase'} text={i.title} ref={el => titleRef.current[k] = el} />
                                    </div>
                                    <div className="overflowHidden" >
                                        <Text className={'description pXS NotoSansRegular'} text={i.description} ref={el => descriptionRef.current[k] = el} />
                                    </div>
                                </div>
                                <div className="textAlignRight overflowHidden date">
                                    <Text className={'otherElementAnimation pXS NotoSansRegular uppercase'} text={i.date} ref={el => dateRef.current[k] = el} />
                                </div>
                            </CustomLink>
                        </div>
                    )
                })
            )
        }
        else if (winWidth < 1280) {
            return (
                data.slice(0, 2).map((i, k) => {
                    return (
                        <div className="itemWrap relativeWrapp" key={k}>
                            <div className="otherElementWrapp" ref={el => iconRotateRef.current[k] = el}>
                                <div className="otherElement FlexBox alignMiddle alignCenter">
                                    <Icon className="icon-starIconTitle" />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 490.103 323.007" preserveAspectRatio="none">
                                        <path d="M288.536,323.007h0A336.056,336.056,0,0,0,270.09,214.076a335.937,335.937,0,0,0-21.9-49.754,338.2,338.2,0,0,0-29.311-45.155,339.768,339.768,0,0,0-35.952-39.784,339.4,339.4,0,0,0-41.818-33.639A337.381,337.381,0,0,0,94.2,19.022,335.418,335.418,0,0,0,42.978,0h490.1A335.515,335.515,0,0,0,288.535,323.006Z" transform="translate(-42.978)" fill="none" />
                                        <path d="M288.489,295.291a335.421,335.421,0,0,1,68.475-177.246A337.863,337.863,0,0,1,481.563,17.9,335.729,335.729,0,0,1,524.918,1.178H51.155A336.788,336.788,0,0,1,94.7,17.955a338.545,338.545,0,0,1,47.074,26.81A340.621,340.621,0,0,1,183.74,78.521a340.983,340.983,0,0,1,36.076,39.923,339.337,339.337,0,0,1,29.414,45.314,337.273,337.273,0,0,1,21.973,49.93,336.081,336.081,0,0,1,17.286,81.6m.047,27.716h0A336.064,336.064,0,0,0,270.09,214.072a336.05,336.05,0,0,0-21.9-49.754,338.329,338.329,0,0,0-29.311-45.156,339.833,339.833,0,0,0-35.952-39.785,339.4,339.4,0,0,0-41.818-33.638A337.405,337.405,0,0,0,94.2,19.022,335.431,335.431,0,0,0,42.978,0h490.1a334.108,334.108,0,0,0-97.735,45.6A337.1,337.1,0,0,0,328.709,163.753a333.883,333.883,0,0,0-40.173,159.254Z" transform="translate(-42.978 0)" />
                                    </svg>
                                </div>
                            </div>
                            <CustomLink url={config.routes.news.path + "/" + i.slug} className={"linkWrap FlexBoxColumn spaceBetween otherElementAnimation"} ref={el => itemsRef.current[k] = el} >
                                <div>
                                    <div className="iconWrap textAlignCenter">
                                        <Icon className="icon-arrowRight" />
                                    </div>
                                    <div className="overflowHidden" >
                                        <Text className={'otherElementAnimation pL GHEAMariamBold uppercase'} text={i.title} ref={el => titleRef.current[k] = el} />
                                    </div>
                                    <div className="overflowHidden" >
                                        <Text className={'description pXS NotoSansRegular'} text={i.description} ref={el => descriptionRef.current[k] = el} />
                                    </div>
                                </div>
                                <div className="textAlignRight overflowHidden date">
                                    <Text className={'otherElementAnimation pXS NotoSansRegular uppercase'} text={i.date} ref={el => dateRef.current[k] = el} />
                                </div>
                            </CustomLink>
                        </div>
                    )
                })
            )
        }
    }, [data, winWidth])

    return data.length != 0 &&
        <AnimationWrapp state={secObj} trigerEl={contRef?.current} startPoint={'top+=30% bottom'}>
            <NewsStyle>
                <Container ref={contRef} className='containerPaddBottom'>
                    <div className="FlexBox">
                        <Text className={'singleTitle h3 GHEAMariamBold uppercase margBottomTitle'} text={"News"} />
                    </div>
                    <div className="newsItemsWrap FlexBoxContainer alignMiddle">
                        {storeNews}
                    </div>
                    <div className="overflowHidden" >
                        <Button text={'SeeMoreNews'} link={config.routes.news.path} btnAnimation={true} wrappClassName={"otherElementAnimation FlexBox alignCenter"} />
                    </div>
                </Container>
            </NewsStyle>
        </AnimationWrapp>
})

export default withLanguageContext(withUIContext(NewsSection,
    ['winWidth', 'screenSizes']),
    ['translate', 'selectedLang'])