import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Title, Container, Image, AnimationWrapp, CircleContainer } from 'components/common'

//* Config
import { arrayGenChild } from 'helpers'


const SupportTopSection = Memo(({ selectedLang, ...props }) => {


    //! States
    const [secObj, setSecObj] = useState()
    const [secTopObj, setSecTopObj] = useState()
    const [startAnimation, setStartAnimation] = useState(false)

    //! Refs
    const titleRef = useRef()
    const contRef = useRef()

    useEffect(() => {
        if (
            titleRef?.current && contRef?.current
        ) {
            setSecObj([
                arrayGenChild(titleRef.current[0]),
            ]);
            setSecTopObj(titleRef.current)
        }

    }, [titleRef, contRef, props.description, selectedLang])

    const StartAnim = (x) => {
        (x && !startAnimation) ? setStartAnimation(true) : setStartAnimation(false)
    }

    return (
        <AnimationWrapp
            state={secObj}
            sectionTop={secTopObj}
            sectionAnimationStart={e => StartAnim(e)}
        >
            <div className="SupportTopSection ">
                <Container className="containerPaddTop overflowHidden" ref={contRef}>
                    <div className="FlexBox alignCenter">
                        <Title wrapClassName={'alignReverse margBottomTitle'}
                            ref={titleRef}
                            title={
                                {
                                    1: [
                                        { type: 'big', name: '   ', space: true },
                                        { type: 'min', name: 'Your', space: true },
                                        { type: 'big', name: 'Support', space: false },
                                    ],
                                    2: [
                                        { type: 'big', name: 'IsVital', space: true },
                                        { type: 'icon' },
                                    ]
                                }
                            }
                        />
                    </div>
                    <CircleContainer startAnimation={startAnimation} >
                        <div className="videoWrapper relativeWrapp overflowHidden">
                            <Image className={'homepageFilms'} src={'/images/SupportSection/galleryImage.jpg'} alt={'gallery image'} />
                        </div>
                    </CircleContainer>
                </Container>
            </div>
        </AnimationWrapp>
    )
})

export default withLanguageContext(SupportTopSection, ['selectedLang'])
