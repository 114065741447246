import React, { memo as Memo, useMemo, useEffect, useRef, useState, useCallback } from 'react'

import { gsap } from 'gsap'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { Text, Title, Button, Translate, Container, AnimationWrapp } from 'components/common'
import ImagesContainer from './ImagesContainer'

//* Config
import { config, arrayGen, arrayGenChild } from 'helpers'

//* Style
import SupportStyle from './style'

const SupportSection = Memo(({ selectedLang, winWidth, isSafari, ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [secTopObj, setSecTopObj] = useState()
	const [startAnimation, setStartAnimation] = useState(false)

	//! Refs
	const words1Ref = useRef()
	const words2Ref = useRef()
	const groupRef = useRef()
	const titleRef = useRef()
	const contRef = useRef()
	const circleRef1 = useRef()
	const circleRef2 = useRef()

	//! Animation
	const tl = useMemo(() => gsap.timeline({ paused: true, repeat: -1 }), [])
	const tl2 = useMemo(() => gsap.timeline({ paused: true, repeat: false }), [])

	useEffect(() => {
		tl.to(words1Ref.current, 20, {
			attr: { startOffset: '50%' },
			ease: 'none',
		})
		tl2.to([circleRef1.current, circleRef2.current], 2.5, {
			ease: 'back.out(1.7)',
			rotation: 0,
			rotationY: 0,
		})

		return () => {
			tl.kill()
			tl2.kill()
		}
	}, [])

	useEffect(() => {
		if (startAnimation) {
			tl.play()
			tl2.play()
		} else {
			tl2.reverse(-1)
		}
	}, [startAnimation])

	useEffect(() => {
		if (groupRef?.current && contRef?.current) {
			setSecTopObj(contRef.current[0])
			setSecObj([arrayGen([arrayGenChild(titleRef.current[0]), groupRef.current.childNodes])])
		}
	}, [titleRef, groupRef, contRef, selectedLang])

	const StartAnim = (x) => {
		x && !startAnimation ? setStartAnimation(true) : setStartAnimation(false)
	}

	//! Global Varibales
	const fastSpeed = useMemo(() => 15, [])
	const slowSpeed = useMemo(() => 50, [])

	//! Animation Updata

	const toggleHover2 = useCallback(
		(startDuration) => {
			tl.duration(startDuration)
			tl.duration(startDuration)
		},
		[words1Ref]
	)

	return (
		<section className='overflowHidden'>
			<AnimationWrapp
				state={secObj}
				sectionTop={secTopObj}
				sectionAnimationStart={(e) => StartAnim(e)}
				animationDuration={0.5}
				animationStagger={0.03}
			>
				<Container className='containerPaddTopBottom' ref={contRef}>
					<SupportStyle className='SupportUsWrapp FlexBox alignMiddle alignCenter relativeWrapp'>
						<div className='SupportUsInfoWrapp FlexBoxContainer alignCenter'>
							<Title
								className={'justifyEnd'}
								childClassName={'fontSize32'}
								ref={titleRef}
								title={{
									1: [
										{ type: 'big', name: 'WeNeedYourSupport1', space: true },
										{ type: 'min', name: 'WeNeedYourSupport2' },
									],
									2: [
										{ type: 'icon' },
										{ type: 'big', name: 'WeNeedYourSupport3', space: true },
										{ type: 'min', name: 'WeNeedYourSupport4' },
									],
								}}
							/>
							<div className='FlexBoxContainer alignCenter' ref={groupRef}>
								<Text tag={'p'} className={'description pM NotoSansRegular'} text={props.donateText} />

								<Button
									text={config.routes.supportUs.name}
									link={config.routes.supportUs.path}
									btnFrame={true}
									wrappClassName={'otherElementAnimation'}
									onMouseEnter={() => toggleHover2(slowSpeed)}
									onMouseOut={() => toggleHover2(fastSpeed)}
								/>
							</div>
						</div>
						<div className='textSvgWrapper' ref={circleRef1}>
							<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 2258 1073'>
								<path
									id='master'
									d='M46.2,366C87.1,107.4 604.8000000000002,-25.600000000000023 1202.5,69C1800.1999999999998,163.60000000000002 2251.6,450 2210.6,708.6C2169.6,967.2 1652,1100.2 1054.3,1005.6C456.59999999999997,911 5.300000000000011,624.6 46.2,366C87.1,107.4 604.8000000000002,-25.600000000000023 1202.5,69C1800.1999999999998,163.60000000000002 2251.6,450 2210.6,708.6C2169.6,967.2 1652,1100.2 1054.3,1005.6C456.6,911 5.3,624.6 46.2,366'
									strokeMiterlimit='90'
								/>
								<text ref={words2Ref} textLength={winWidth < 768 ? '1000' : '0'} wordSpacing='15'>
									<textPath
										xlinkHref='#master'
										ref={words1Ref}
										textLength={isSafari ? '290%' : winWidth < 768 ? '270%' : '226%'}
										startOffset='0%'
									>
										<Translate val={'WeNeedYourSupport'} />
										&nbsp;
										<Translate val={'WeNeedYourSupport'} />
										&nbsp;
										<Translate val={'WeNeedYourSupport'} />
										&nbsp;
										{selectedLang !== 'hy' ? (
											<>
												<Translate val={'WeNeedYourSupport'} />
												&nbsp;
												<Translate val={'WeNeedYourSupport'} />
												&nbsp;
												<Translate val={'WeNeedYourSupport'} />
												&nbsp;
											</>
										) : (
											''
										)}
									</textPath>
								</text>
							</svg>
						</div>

						<ImagesContainer ref={circleRef2} />
					</SupportStyle>
				</Container>
			</AnimationWrapp>
		</section>
	)
})

export default withLanguageContext(withUIContext(SupportSection, ['winWidth']), ['selectedLang'])
