import React, { memo as Memo, useMemo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components

import { Title, Image, Container, AnimationWrapp } from 'components/common'

//* Config
import { arrayGenChild } from 'helpers'

//* Style
import GuestsStyle from './style'

const Guests = Memo(({ selectedLang, winWidth, winHeight, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()
    const [active, setActive] = useState('')

    //! Refs
    const titleRef = useRef()
    const contRef = useRef()
    const parentRef = useRef()
    const imgWrapper = useRef()

    useEffect(() => {
        if (titleRef?.current) {
            setSecObj([arrayGenChild(titleRef.current[0])])
        }
    }, [titleRef, selectedLang])

    useEffect(() => {
        let widerScreenWidth = window.matchMedia("(min-width: 1280px)");

        if (widerScreenWidth.matches) {
            parentRef.current.addEventListener('mousemove', move)
        }
    }, [])

    let imgNum = 0
    // setting threshold to make a gab between images
    const threshold = 175

    let lastPosX, lastPosY, curPosX, curPosY
    let isCounting = true
    let startFromX, startFromY

    const move = (e) => {
        const [x, y] = [e.x, e.y]

        const hasCrossedThresHold = // Boolean value wheather the mouse has crossed the theshold or not
            x > startFromX + threshold || x < startFromX - threshold || y > startFromY + threshold || y < startFromY - threshold

        if (hasCrossedThresHold) {
            // if it has calling showNextImage
            showNextImage(e)
            isCounting = true // changing the isCounting to true
        }

        if (isCounting) {
            // So that we can record the another point here again !!
            startFromX = x
            startFromY = y
        }

        isCounting = false // Changing the isCounting to false to not let recording the startFromX and
        //startFromY points on every mouse-move
    }

    function showNextImage(e) {
        let allImages = imgWrapper.current?.childNodes
        const movingImage = allImages[imgNum]
            ;[curPosX, curPosY] = [e.x, e.y]

        movingImage.removeAttribute = 'style'

        // Setting the position of image
        movingImage.style.opacity = `1`
        movingImage.style.left = `${curPosX}px`
        movingImage.style.top = `${curPosY - parentRef.current.getBoundingClientRect().y}px `

        // making the image visibile here
        movingImage.classList.add('visible')

        // calculating a moving distance
        const movingDistanceX = ((curPosX - lastPosX || 0) * 80) / 100
        const movingDistanceY = ((curPosY - lastPosY || 0) * 80) / 100

        setTimeout(function () {
            // animating image towards the current position of mouse
            movingImage.style.opacity = `1`
            movingImage.style.left = `${lastPosX + movingDistanceX}px`
            movingImage.style.top = `${lastPosY + movingDistanceY - parentRef.current.getBoundingClientRect().y}px`

            setTimeout(function () {
                movingImage.classList.add('grow-scale') // hiding image after 800ms

                setTimeout(function () {
                    movingImage.classList.remove('visible', 'grow-scale')
                    movingImage.style.opacity = `0`
                }, 800)
            }, 400)
        }, 10)

        imgNum++ // incresing num to show different image  each time

        if (imgNum === allImages.length - 1) {
            imgNum = 0
        }

        // Setting the last position values of image
        lastPosX = curPosX
        lastPosY = curPosY
    }

    useEffect(() => {
        let widerScreenWidth = window.matchMedia("(max-width: 1279px)");

        if (widerScreenWidth.matches) {
            window.addEventListener('scroll', scroll)

            return () => {
                window.removeEventListener('scroll', scroll)
            }
        }
    }, [])

    const scroll = () => {
        if (imgWrapper?.current?.getBoundingClientRect().y < winHeight * 0.3 && imgWrapper?.current?.getBoundingClientRect().bottom > winHeight * 0.3) {
            setActive('active')
        } else {
            setActive('')
        }
    }

    return (
        <AnimationWrapp state={secObj}>
            <GuestsStyle ref={parentRef} className='FlexBox alignMiddle alignCenter relativeWrapp'>
                <Container className='overflowHidden containerPaddTopBottom' ref={contRef}>
                    <Image className='bg' src='/images/AboutMuseum/guestsBackground.svg' />
                    <Title
                        className={'justifyEnd '}
                        ref={titleRef}
                        title={{
                            1: [{ type: 'min', name: 'our', space: false }],
                            2: [{ type: 'big', name: 'famous' }],
                            3: [{ type: 'icon' }, { type: 'min', name: 'guests', space: false }],
                        }}
                    />
                    <div ref={imgWrapper} className={`img-wrapper ${active}`}>
                        {
                            props.data.map((item, ind) => {
                                return (
                                    <Image key={ind} className='imgimg' src={item} />
                                )
                            })
                        }
                    </div>
                </Container>
            </GuestsStyle>
        </AnimationWrapp>
    )
})

export default withUIContext(withLanguageContext(Guests,
    ['selectedLang']),
    ['winWidth', 'winHeight'])
