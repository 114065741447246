import styled from 'styled-components'


const SliderStyle = styled.div.attrs(props => ({ className: props.className }))`
    background-color: #BFC1D3;

    section {
        transition: var(--trTime);
        transform:scale(0);
        
        &.active {
            transform:scale(1);
        }
    }

    .swiper-pagination {
        top:unset;
        bottom:calc(-1 * var(--sp9x));
        height: 1px;

        span {
            height: 3px;
            background-color: #183334;
            top:-1px;
        }
    }

    .swiper-container {
        max-width: var(--sp200x);
        width: 100%;
        overflow:visible;
        
        .swiper-button-prev {
            position: absolute;
            top:0;
            left:0;
            width: 50%;
            height: 100%;

            &:after {
                display: none;
            }
        }

        .swiper-button-next {
            position: absolute;
            top:0;
            right:0;
            width: 50%;
            height: 100%;

            &:after {
                display: none;
            }
        }

        .swiper-wrapper {
            width: 100%;

            .swiper-slide {
                width: 100%;
                opacity: 1 !important;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                overflow: hidden;

                &.swiper-slide-active {
                    .imageCont {
                        img {
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                            transition: 0.3s;
                        }
                    }
                }
                
                .imageCont {
                    width: 100%;
                    padding-top:75%;
                    top:0;
                    
                    img {
                        clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
                        transition: 0.3s 0.5s;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}){
        .swiper-pagination {
            bottom:calc(-1 * var(--sp8x));
        }

        .swiper-container {
            max-width: var(--sp148x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.DesktopSizeS}){
        .swiper-pagination {
            bottom:calc(-1 * var(--sp7x));
        }

        .swiper-container {
            max-width: var(--sp115x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}){
        .swiper-pagination {
            bottom:calc(-1 * var(--sp6x));
        }

        .swiper-container {
            max-width: var(--sp101x);
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.LaptopSize}){
        .swiper-pagination {
            bottom:calc(-1 * var(--sp5x));
        }

        .swiper-container {
            max-width: var(--sp94x);
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
        .swiper-pagination {
            bottom:calc(-1 * var(--sp6x));
        }

        .swiper-container {
            max-width: calc(100% - var(--sp10x));
        }
            }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        .swiper-pagination {
            bottom:calc(-1 * var(--sp4x));
        }

        .swiper-container {
            max-width: calc(100% - var(--sp4x));
        }
    }
`

export default SliderStyle