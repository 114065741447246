import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import router from 'next/router'
import { SingleProduct } from 'components/pages'
import { api } from 'helpers'

const ProductList = (props) => {
    const [products, setProducts] = useState()
    const [hasMore, setHasMore] = useState()

    const getMore = () => {
        let params = {
            offset: products.length
        }

        if (router.query.catId) {
            params.category_id = router.query.catId
        }

        api.get('get-products', router.locale, params).then(props => {
            setProducts((products) => [...products, ...props.products])
            setHasMore(props.hasMore)
        })
    }

    const storeProducts = useMemo(() => {
        return products?.map((i, k) => (
            <SingleProduct
                key={k}
                product={i}
            />
        ))
    }, [products])

    useEffect(() => {
        setProducts(props.products)
        setHasMore(props.hasMore)
    }, [props.products, props.hasMore])

    return <InfiniteScroll
        next={getMore}
        hasMore={hasMore}
        dataLength={products?.length || 0}
    >
        <div className={'FlexBoxContainer'}>
            {storeProducts}
        </div>
    </InfiniteScroll>
}

export default ProductList