import styled from 'styled-components'

const KaleidoscopeSectionStyle = styled.div`
	--size: var(--sp93x);
	--iconSize: var(--sp10x);
	--padding: var(--sp10x);

	min-height: 90vh;
	position: relative;
	background-color: var(--textColor);

	.infoWrappere {
		text-align: center;
		position: relative;
		z-index: 23;
		border-radius: 100%;
		width: var(--size);
		height: var(--size);
		background-color: var(--backColor);
		padding: calc(var(--padding) * 2) var(--padding);
		transition: all var(--trTime3) ease;
		clip-path: circle(0% at 50% 50%);
		&.active {
			clip-path: circle(50% at 50% 50%);
		}
		> span {
			transform: scale(0);
		}
		i {
			display: inline-block;
			font-size: var(--iconSize);
			animation: starIconAnim 10s linear 0s infinite;
		}
	}

	@keyframes starIconAnim {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--size: var(--sp70x);
		--iconSize: var(--sp7x);
		--padding: var(--sp8x);

		.imageCont {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--size: var(--sp52x);
		--iconSize: var(--sp5-5x);
		--padding: var(--sp4x);

		.imageCont {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--size: var(--sp43x);
		--iconSize: var(--sp5x);
		--padding: var(--sp4x);
		min-height: auto;

		.imageCont {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		min-height: var(--sp128x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		min-height: var(--sp112x);
	}
`

export default KaleidoscopeSectionStyle
