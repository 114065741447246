import React, { memo as Memo, useState, useRef, useEffect } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* JSON's
import config from 'helpers/_config'

//* Component
import { Icon, LottieAnimation, AnimationWrapp, Text, Image, Container, Number, Button } from 'components/common'

//* Style
import MuseumStatsStyle from './style'

import lottie1 from 'lottieAnimation/AboutMuseumLotties/lottie1.json'
import lottie2 from 'lottieAnimation/AboutMuseumLotties/lottie2.json'
import lottie3 from 'lottieAnimation/AboutMuseumLotties/lottie3.json'
import lottie4 from 'lottieAnimation/AboutMuseumLotties/lottie4.json'

const MuseumStats = Memo(({ winWidth, winHeight, selectedLang, ...props }) => {
    const quoteRef = useRef()
    const quoteTopRef = useRef()
    const autorRef = useRef()
    const imgRef = useRef()
    const text = useRef()
    const textTop = useRef()
    const bottomRef = useRef()

    //! States
    const [secObj, setSecObj] = useState()
    const [activeClass, setActiveClass] = useState('')
    const [activeBottom, setActiveBottom] = useState('')

    useEffect(() => {
        if ((text?.current && quoteRef?.current, autorRef?.current)) {
            setSecObj([
                [textTop.current, text.current],
                [quoteTopRef.current, quoteRef.current, autorRef.current],
            ])
        }
    }, [text && quoteRef, autorRef, selectedLang])

    useEffect(() => {
        window.addEventListener('scroll', scroll)
        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (imgRef?.current[0]?.getBoundingClientRect().y < winHeight * 0.7) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }

        if (bottomRef.current.getBoundingClientRect().y < winHeight * 0.6) {
            setActiveBottom('active')
        } else {
            setActiveBottom('')
        }
    }

    return (
        <AnimationWrapp state={secObj}>
            <MuseumStatsStyle className='FlexBoxColumn'>
                <Container className='containerPaddTopBottom'>
                    <div className='top FlexBoxColumn'>
                        <div className='top-block FlexBox'>
                            <div className='texts FlexBoxColumn'>
                                <div className='text1 overflowHidden'>
                                    <span ref={textTop} />
                                    <div
                                        ref={text}
                                        className='description'
                                        dangerouslySetInnerHTML={{ __html: props.data.text }}
                                    />
                                </div>
                            </div>
                            <Image className={activeClass} ref={imgRef} src={props.src} alt='image' />
                        </div>
                        <div className='overflowHidden quote-block FlexBoxColumn'>
                            <span ref={quoteTopRef} />
                            <Text ref={quoteRef} tag={'h5'} className={'description quote h5 GHEAMariamRegular'} text={props.data.quote} />
                            <div ref={autorRef} className='description autor FlexBox alignMiddle'>
                                <Icon className='icon-star2 h6' />
                                <Text tag={'p'} className={'name pL GHEAMariamBold uppercase'} text={props.data.quoteAuthor} />
                            </div>
                        </div>
                    </div>
                </Container>
                <Container className='containerPaddBottom'>
                    <div ref={bottomRef} className={`bottom FlexBox ${activeBottom}`}>
                        <div className='lot lot-1 FlexBoxColumn'>
                            <LottieAnimation loop={true} autoplay={true} animData={lottie1} />
                            <Number tag={'h1'} className={'h1 num'} font={'GHEAMariamRegular'} end={props.data.fact1Num} />
                            <Text tag={'p'} className={'tit p NotoSansRegular'} text={props.data.fact1} />
                        </div>
                        <div className='lot lot-2 FlexBoxColumn'>
                            <LottieAnimation loop={true} autoplay={true} animData={lottie2} />
                            <Number tag={'h1'} className={'h1 num'} font={'GHEAMariamRegular'} end={props.data.fact2Num} />
                            <Text tag={'p'} className={'tit p NotoSansRegular'} text={props.data.fact2} />
                        </div>
                        <div className='lot lot-3 FlexBoxColumn'>
                            <LottieAnimation loop={true} autoplay={true} animData={lottie3} />
                            <Number tag={'h1'} className={'h1 num'} font={'GHEAMariamRegular'} end={props.data.fact3Num} />
                            <Text tag={'p'} className={'tit p NotoSansRegular'} text={props.data.fact3} />
                        </div>
                        <div className='lot lot-4 FlexBoxColumn'>
                            <LottieAnimation loop={true} autoplay={true} animData={lottie4} />
                            <Number tag={'h1'} className={'h1 num'} font={'GHEAMariamRegular'} end={props.data.fact4Num} />
                            <Text tag={'p'} className={'tit p NotoSansRegular'} text={props.data.fact4} />
                        </div>
                    </div>

                    <Button link={config.routes.gallery.path} text={'visitArtGallery'} btnAnimation={true} wrappClassName={'FlexBox alignCenter'} />
                </Container>
            </MuseumStatsStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(MuseumStats, ['winWidth', 'winHeight']), ['selectedLang'])
