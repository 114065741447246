import styled from 'styled-components'

const SingleProductStyle = styled.div.attrs((props) => ({ className: props.className }))`
	width: 33.333%;
	position: relative;
	background-color: var(--textColor);
	transition: var(--trTime);

	&.active {
		.images .image-prod {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			transition: var(--trTime) 1s;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: var(--backColor);
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 1px;
		background-color: var(--backColor);
	}

	.link {
		color: var(--backColor);
		padding: var(--sp6x) var(--sp9x);
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.images {
		width: 95.6%;
		padding-top: 84.7%;
		position: relative;
		margin-top: var(--sp9x);
		transition: all var(--trTime) linear;

		.imageCont img {
			object-fit: contain;
		}

		.image-prod {
			transition: var(--trTime);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
			z-index: 2;
		}

		.load-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: scale(0.65);
			animation: loadAnimRotate 1.333s infinite cubic-bezier(0.48, 0.04, 0.52, 0.96);
		}

		@keyframes loadAnimRotate {
			0% {
				transform: scale(0.45) rotate(-7deg) translateY(0px);
			}

			25% {
				transform: scale(0.45) translateY(-40px);
			}

			50% {
				transform: scale(0.45) rotate(7deg) translateY(0px);
			}

			75% {
				transform: scale(0.45) translateY(-40px);
			}

			100% {
				transform: scale(0.45) rotate(-7deg);
			}
		}
	}

	.info {
		width: 100%;
		align-items: flex-start;
		flex: 1;

		.name {
			margin-right: var(--sp10x);
		}
	}

	&:hover {
		border-radius: 8%;

		.images {
			transform: scale(1.05);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.link {
			padding: var(--sp5x) var(--sp8x);
		}

		a {
			padding: var(--sp5x) var(--sp8x);
		}

		.info {
			.name {
				margin-right: var(--sp4x);
			}
		}

		.images {
			width: 100%;
			padding-top: 88.5%;
			margin-top: var(--sp8x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.link {
			padding: var(--sp4x) var(--sp5x);
		}
		a {
			padding: var(--sp4x) var(--sp5x);
		}

		.info {
			.name {
				margin-right: var(--sp3x);
			}
		}

		.images {
			width: 96%;
			padding-top: 85%;
			margin-top: var(--sp7x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		.link {
			padding: var(--sp3x) var(--sp4x);
		}
		a {
			padding: var(--sp3x) var(--sp4x);
		}

		.info {
			.name {
				margin-right: var(--sp3x);
			}
		}

		.images {
			width: 96%;
			padding-top: 85%;
			margin-top: var(--sp6x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		width: 50%;

		.link {
			padding: var(--sp3x) var(--sp4x);
		}

		a {
			padding: var(--sp3x) var(--sp4x);
		}

		.info {
			.name {
				margin-right: var(--sp3x);
			}
		}

		.images {
			width: 76.6%;
			padding-top: 67%;
			margin-top: var(--sp6x);

			@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
				width: 84.7%;
				padding-top: 75%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		width: 100%;

		.link {
			padding: var(--sp3x) var(--sp2x);
		}

		a {
			padding: var(--sp3x) var(--sp2x);
		}

		.info {
			.name {
				margin-right: var(--sp3x);
			}
		}

		&:after {
			display: none;
		}

		.images {
			width: 71%;
			padding-top: 62.7%;
			margin-top: var(--sp5x);
		}
	}
`
export default SingleProductStyle
