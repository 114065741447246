import styled from 'styled-components'


const PartnersStyle = styled.div.attrs(props => ({ className: props.className }))`
    padding:var(--containerMarginTop) 0;

    section {
        &.active {
            .top {
                .general-partner {
                    .imageCont {
                        img {
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                        }
                    }
                }
            }

            .bottom {
                .partner {
                    .imageCont {
                        img {
                            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                        }
                    }

                    .comp-title {
                        transform: translateY(0);
                    }
                }
            }
        }

        .top {
            & > .title {
                max-width: var(--sp102x);
            }

            .general-partner {
                max-width:var(--sp123x);

                .imageCont {
                    width: 28%;
                    padding-top: 23.6%;

                    img {
                        transition: var(--trTime);
                        clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
                    }
                }

                .title {
                    margin:var(--sp4x) 0;
                }
            }
        }

        .bottom {
            flex-wrap: wrap;
            
            .partner {
                margin-top:var(--sp13x);
                width: var(--sp47x);
                margin-right: var(--sp30x);
                
                &:nth-child(4n){
                    margin-right: 0;
                }

                .imageCont {
                    width: var(--sp24x);
                    padding-top:var(--sp24x);
                    margin-bottom: var(--sp4x);

                    img {
                        object-fit: contain;
                        transition: var(--trTime);
                        clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
                    }
                }
                
                .comp-title {
                    transition: var(--trTime);
                    flex:1;
                }
            }
        }
    }
    

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}){
        section {
            .top {
                & > .title {
                    max-width: var(--sp80x);
                }

                .general-partner {
                    max-width:var(--sp93x);

                    .title {
                        margin:var(--sp3x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    margin-top:var(--sp10x);
                    width: var(--sp34x);
                    margin-right: var(--sp23x);

                    .imageCont {
                        width: var(--sp19x);
                        padding-top:var(--sp19x);
                        margin-bottom: var(--sp3x);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.DesktopSizeS}){
        section {
            .top {
                & > .title {
                    max-width: var(--sp60x);
                }

                .general-partner {
                    max-width:var(--sp67x);

                    .title {
                        margin:var(--sp3x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    margin-top:var(--sp9x);
                    width: var(--sp32x);
                    margin-right: var(--sp10x);

                    .imageCont {
                        width: var(--sp19x);
                        padding-top:var(--sp19x);
                        margin-bottom: var(--sp3x);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}){
        section {
            .top {
                & > .title {
                    max-width: var(--sp55x);
                }

                .general-partner {
                    max-width:var(--sp58x);

                    .title {
                        margin:var(--sp2x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    width: var(--sp28x);
                    margin-top:var(--sp8x);
                    margin-right: var(--sp10x);

                    .imageCont {
                        width: var(--sp15x);
                        padding-top:var(--sp15x);
                        margin-bottom: var(--sp2x);
                    }
                }
            }
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.LaptopSize}){
        section {
            .top {
                & > .title {
                    max-width: var(--sp33x);
                }

                .general-partner {
                    max-width:var(--sp58x);

                    .title {
                        margin:var(--sp2x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    margin-top:var(--sp7x);
                    width: var(--sp24x);
                    margin-right: var(--sp7x);

                    .imageCont {
                        width: var(--sp15x);
                        padding-top:var(--sp15x);
                        margin-bottom: var(--sp2x);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
        section {
            .top {
                & > .title {
                    max-width: var(--sp33x);
                }

                .general-partner {
                    max-width:var(--sp58x);

                    .title {
                        margin:var(--sp2x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    margin-top:var(--sp7x);
                    width: var(--sp32x);
                    margin-right: 0;
                    
                    &:nth-child(2n-1) {
                        margin-right: var(--sp20x);
                    }

                    .imageCont {
                        width: var(--sp15x);
                        padding-top:var(--sp15x);
                        margin-bottom: var(--sp2x);
                    }
                }
            }
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        section {
            .top {
                flex-direction: column;

                & > .title {
                    max-width: var(--sp33x);
                }

                .general-partner {
                    max-width:unset;
                    margin-top:var(--sp6x);

                    .imageCont {
                        width: 42%;
                        padding-top: 35.6%;
                    }

                    .title {
                        margin:var(--sp2x) 0;
                    }
                }
            }

            .bottom {
                
                .partner {
                    margin-top:var(--sp6x);
                    width: calc(50% - var(--spSize));
                    margin-right: 0;
                    
                    &:nth-child(2n-1) {
                        margin-right: var(--spSize);
                    }
                    &:nth-child(2n) {
                        margin-left: var(--spSize);
                    }

                    .imageCont {
                        width: var(--sp12x);
                        padding-top:var(--sp12x);
                        margin-bottom: var(--sp2x);
                    }
                }
            }
        }
    }
`

export default PartnersStyle