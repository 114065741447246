import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Text, AnimationWrapp, Button } from 'components/common'

//* Config
import { arrayGen, config } from 'helpers'


const SupportContactSection = Memo(({ selectedLang, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const groupRef = useRef()

    useEffect(() => {
        if (props.title && props.description && groupRef?.current) {
            setSecObj([
                arrayGen([groupRef.current.childNodes[0].firstChild,
                groupRef.current.childNodes[1].firstChild,
                groupRef.current.childNodes[2].firstChild])
            ])
        }

    }, [props.title, props.description, groupRef, selectedLang])


    return (
        <AnimationWrapp state={secObj} >
            <Container className="contactSection containerPaddBottom overflowHidden">

                <div className="contactSection" ref={groupRef}>
                    <div className="overflowHidden FlexBoxContainer alignCenter">
                        <Text tag={'p'} className={'otherElementAnimation titleContactSection h4 GHEAMariamRegular'} text={props.title} />
                    </div>
                    <div className="overflowHidden FlexBoxContainer alignCenter">
                        <Text tag={'p'} className={'description pM NotoSansRegular textAlignCenter'} text={props.description} />
                    </div>
                    <div className="overflowHidden FlexBoxContainer alignCenter">
                        <Button text={"ContactUs"} link={config.routes.contact.path} btnAnimation={true} wrappClassName={"otherElementAnimation"} />
                    </div>
                </div>
            </Container>
        </AnimationWrapp>
    )
})

export default withLanguageContext(SupportContactSection, ['selectedLang'])
