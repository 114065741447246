import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button, Container } from 'components/common'
import { routerToPathAndQuery } from 'helpers'

import FilterBarStyle from './style'

const FilterBar = ({ categories }) => {
    const router = useRouter()
    const [active, setActive] = useState(null)

    const onCategoryChange = useCallback((catId) => {
        const { pathname, query } = routerToPathAndQuery(router, 'catId', catId, false)
        router.push({ pathname, query }, undefined, { scroll: true })
        catId === active ?
            setActive(null)
            :
            setActive(catId)
    }, [router.query])

    return (
        <FilterBarStyle>
            <Container>
                <div className="filter-btns FlexBox">
                    <Button className={`filter-btn ${active == null ? 'active' : ''}`} text={'All'}
                        onClick={() => onCategoryChange(null)} />
                    {categories.map((i, k) => (
                        <Button
                            key={k}
                            className={`filter-btn ${active == i.id ? 'active' : ''}`}
                            text={i.name}
                            onClick={() => onCategoryChange(i.id)} />
                    ))}
                </div>
            </Container>
        </FilterBarStyle>

    )
}

export default FilterBar