import styled from 'styled-components'

const MuseumStatsStyle = styled.div.attrs((props) => ({ className: props.className }))`
    .description {
        p {
            font-size: var(--pM);
            font-family: NotoSansRegular;
        }
    }

	section {
		.top {
			.top-block {
				align-items: flex-start;

				.texts {
					flex: 1;
					margin-right: var(--sp16x);

					& > :last-child {
						margin-top: var(--sp4x);
					}
				}

				.imageCont {
					width: 62%;
					padding-top: 41.2%;

					&.active {
						img {
							clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
						}
					}

					img {
						transition: var(--trTime);
						clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
					}
				}
			}

			.quote-block {
				margin-top: var(--containerMarginBottom);
				width: 100%;

				.autor {
					margin-top: var(--sp9x);

					i {
						line-height: 1;
						animation: rotate 6s infinite linear;
					}

					@keyframes rotate {
						0% {
							transform: rotate(0);
						}

						100% {
							transform: rotate(360deg);
						}
					}

					.name {
						margin-left: var(--sp3x);
					}
				}
			}
		}

		.bottom {
			justify-content: space-between;
			align-items: flex-end;

			&.active {
				.lot {
					transform: scale(1);
				}
			}

			.lot {
				max-width: var(--sp65x);
				width: 100%;
				text-align: center;
				transform: scale(0);
				transition: var(--trTime);

				&:nth-child(even) {
					margin-bottom: var(--sp32x);
				}

				.LottieWrapp {
					flex: 1;
				}

				.tit {
					flex: 1;
				}
			}
		}

		.btn-cont {
			align-self: center;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.top {
				.top-block {
					.texts {
						margin-right: var(--sp14x);
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp8x);
					}
				}
			}

			.bottom {
				.lot {
					max-width: var(--sp65x);

					&:nth-child(even) {
						margin-bottom: var(--sp20x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.top {
				.top-block {
					.texts {
						margin-right: var(--sp6x);
					}

					.imageCont {
						width: 59%;
						padding-top: 39.2%;
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp7x);
					}
				}
			}

			.bottom {
				.lot {
					&:nth-child(even) {
						margin-bottom: var(--sp16x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		section {
			.top {
				.top-block {
					.texts {
						margin-right: var(--sp5x);
					}

					.imageCont {
						width: 59%;
						padding-top: 39.2%;
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp5x);
					}
				}
			}

			.bottom {
				.lot {
					&:nth-child(even) {
						margin-bottom: var(--sp16x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		section {
			.top {
				.top-block {
					.texts {
						margin-right: var(--sp3x);
					}

					.imageCont {
						width: 49%;
						padding-top: 32.2%;
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp3x);
					}
				}
			}

			.bottom {
				.lot {
					&:nth-child(even) {
						margin-bottom: var(--sp12x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		section {
			.top {
				.top-block {
					flex-direction: column-reverse;

					.texts {
						margin-right: 0;
                        margin-top: var(--sp3x);
					}

					.imageCont {
						width: 100%;
						padding-top: 66.4%;
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp3x);
					}
				}
			}

			.bottom {
                flex-wrap: wrap;

				.lot {
                    width: 50%;
                    
					&:nth-child(even) {
						margin-bottom: var(--sp12x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        section {
			.top {
				.top-block {
					flex-direction: column-reverse;

					.texts {
						margin-right: 0;
                        margin-top: var(--sp3x);
					}

					.imageCont {
						width: 100%;
						padding-top: 66.4%;
					}
				}

				.quote-block {
					.autor {
						margin-top: var(--sp3x);

                        .name {
                            margin-left: var(--sp2x);
                        }
					}
				}
			}

			.bottom {
                flex-wrap: wrap;
				.lot {
                    width: 50%;
                    
					&:nth-child(even) {
						margin-bottom: var(--sp12x);
					}
				}
			}
		}
	}
`

export default MuseumStatsStyle
