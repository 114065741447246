import React, { memo as Memo, useEffect, useRef, useState, useMemo } from 'react'

import { gsap, Elastic } from 'gsap'
import MorphSVGPlugin from 'gsap/dist/MorphSVGPlugin'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Config
import { config, arrayGenChild } from 'helpers'

//* Components
import { Image, Title, Container, CustomLink, AnimationWrapp } from 'components/common'

//* Style
import StepInToParajanovStyle from './style'

gsap.registerPlugin(MorphSVGPlugin);

const StepInToParajanovSection = Memo(({ selectedLang, winWidth, ...props }) => {

    //! States
    const [secObj, setSecObj] = useState()
    const [secTopObj, setSecTopObj] = useState()
    const [startAnimation, setStartAnimation] = useState(false)


    //! Refs
    const titleRef = useRef()
    const imagesFallRef = useRef()
    const contRef = useRef()


    // //! Animation
    const tl = useMemo(() => gsap.timeline({ paused: true, repeat: false }), [])
    const tlWiggle = useMemo(() => gsap.timeline({ paused: true, repeat: false }), [])
    const tlLines = useMemo(() => gsap.timeline({ paused: true, repeat: false }), [])

    useEffect(() => {
        if (
            titleRef?.current && contRef?.current) {
            setSecTopObj(contRef.current)
            setSecObj([
                arrayGenChild(titleRef.current[0]),
            ])
        }
    }, [titleRef, contRef, selectedLang])


    useEffect(() => {
        if (winWidth >= 1024) {
            window.addEventListener('scroll', onScroll)

            tl.to([imagesFallRef.current.childNodes[0].firstChild.lastChild,
            imagesFallRef.current.childNodes[1].firstChild.lastChild,
            imagesFallRef.current.childNodes[2].firstChild.lastChild,
            imagesFallRef.current.childNodes[3].firstChild.lastChild], 0.9, {
                stagger: 0.01,
                transformOrigin: "top center",
                ease: Elastic.easeOut,
                y: 0,
            });

            tlLines.to(".step1", { morphSVG: "#step2", duration: 0.08, ease: 'linaer', })
                .to(".step1", { morphSVG: "#step3", duration: 0.06, ease: 'linaer', })
                .to(".step1", { morphSVG: "#step4", duration: 0.07, ease: 'linaer', });

            tlWiggle.to(imagesFallRef.current.childNodes,
                {
                    duration: 3,
                    stagger: 0.07,
                    rotation: 0,
                    transformOrigin: "top center",
                    ease: Elastic.easeOut
                })

            return (() => {
                tl.kill();
                tlLines.kill();
                tlWiggle.kill();
                window.removeEventListener('scroll', onScroll)
            })

        }


    }, [imagesFallRef, winWidth])



    const onScroll = () => {
        if (tl.progress() === 1 && tlWiggle.progress() === 1 && winWidth >= 1024) {
            tlWiggle.restart();
        }
    };

    useEffect(() => {
        if (winWidth >= 1024) {
            if (startAnimation) {
                tl.play();
                tlLines.play();
                tlWiggle.play();
            }
            else {
                tl.reverse(-1);
                tlLines.reverse(-1);
            }
        }
    }, [winWidth, startAnimation])


    const StartAnim = (x) => {
        (x && !startAnimation) ? setStartAnimation(true) : setStartAnimation(false)
    }

    return (
        <AnimationWrapp
            state={secObj}
            otherStartPoint={'top bottom'}
            sectionTop={secTopObj}
            sectionAnimationStart={e => StartAnim(e)}
        >
            <CustomLink url={config.routes.gallery.path} id="VisitGallery" >
                <StepInToParajanovStyle className='StepInToParajanovInfoWrapp FlexBox relativeWrapp overflowHidden' ref={contRef}>
                    <Container className='FlexBoxContainer alignCenter alignCenter containerPaddTopBottom'>
                        <div>
                            <Title className={'justifyEnd '}
                                ref={titleRef}
                                title={
                                    {
                                        1: [
                                            { type: 'big', name: 'StepInToParajanovsWorld1', space: true },
                                            { type: 'min', name: 'StepInToParajanovsWorld2', space: true },
                                            { type: 'icon' },
                                        ],
                                        2: [
                                            { type: 'big', name: 'StepInToParajanovsWorld3', space: false },
                                        ],
                                        3: [
                                            { type: 'min', name: 'StepInToParajanovsWorld4' }
                                        ]
                                    }
                                }
                            />
                        </div>

                        <div className="galleryWrapp FlexBoxContainer" ref={imagesFallRef}>


                            <div className={'galleryWrap1'}>
                                <div className='gallery1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 364.4 246.6" xmlSpace="preserve">
                                        <path className="step1" id="step1" d="M0.4,148.2c0,0,83.6-59.5,112.6-97.6s38.5-49.8,68.5-49.8c20,0,11.5,13.8,47.5,14.8c16.6,0.5,36.5,18.2,53,37.1c19.2,22,81.5,95.6,81.5,95.6" />
                                        <path className="step2" id="step2" d="M0.4,144.5c0,0,41.6-79.6,71.6-116.6s75.6-25,109.5-27C206.6-0.6,194,22.5,203,35.2S233,49,276,49c36,0,46.5,80.2,87.5,95.1" />
                                        <path className="step3" id="step3" d="M0.4,180c0,0,33.6-42.6,73.6-90.2s72-58.2,86-68.8s21.5-20.1,21.5-20.1S214,40,234,64.4s116.5,88.2,129.5,115.8" />
                                        <polyline className="step4" id="step4" points="0.4,246 85.1,131.3 148.6,45.5 181.5,0.8 246.6,88.4 364,246.6 " />
                                    </svg>

                                    <Image src={'/images/StepInToParajanovSection/Coin.png'} alt={'gallery pictures'} />
                                </div>
                            </div>
                            <div className={'galleryWrap4'}>
                                <div className='gallery4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 364.4 246.6" xmlSpace="preserve">
                                        <path className="step1" d="M0.4,148.2c0,0,83.6-59.5,112.6-97.6s38.5-49.8,68.5-49.8c20,0,11.5,13.8,47.5,14.8c16.6,0.5,36.5,18.2,53,37.1c19.2,22,81.5,95.6,81.5,95.6" />
                                        <path className="step2" d="M0.4,144.5c0,0,41.6-79.6,71.6-116.6s75.6-25,109.5-27C206.6-0.6,194,22.5,203,35.2S233,49,276,49c36,0,46.5,80.2,87.5,95.1" />
                                        <path className="step3" d="M0.4,180c0,0,33.6-42.6,73.6-90.2s72-58.2,86-68.8s21.5-20.1,21.5-20.1S214,40,234,64.4s116.5,88.2,129.5,115.8" />
                                        <polyline className="step4" points="0.4,246 85.1,131.3 148.6,45.5 181.5,0.8 246.6,88.4 364,246.6 " />
                                    </svg>
                                    <Image src={'/images/StepInToParajanovSection/work.png'} alt={'gallery pictures'} />
                                </div>
                            </div>
                            <div className={'galleryWrap2'}>
                                <div className='gallery2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 364.4 246.6" xmlSpace="preserve">
                                        <path className="step1" d="M0.4,148.2c0,0,83.6-59.5,112.6-97.6s38.5-49.8,68.5-49.8c20,0,11.5,13.8,47.5,14.8c16.6,0.5,36.5,18.2,53,37.1c19.2,22,81.5,95.6,81.5,95.6" />
                                        <path className="step2" d="M0.4,144.5c0,0,41.6-79.6,71.6-116.6s75.6-25,109.5-27C206.6-0.6,194,22.5,203,35.2S233,49,276,49c36,0,46.5,80.2,87.5,95.1" />
                                        <path className="step3" d="M0.4,180c0,0,33.6-42.6,73.6-90.2s72-58.2,86-68.8s21.5-20.1,21.5-20.1S214,40,234,64.4s116.5,88.2,129.5,115.8" />
                                        <polyline className="step4" points="0.4,246 85.1,131.3 148.6,45.5 181.5,0.8 246.6,88.4 364,246.6 " />
                                    </svg>
                                    <Image src={'/images/StepInToParajanovSection/Jakonda.png'} alt={'gallery pictures'} />
                                </div>
                            </div>
                            <div className={'galleryWrap3'}>
                                <div className='gallery3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 364.4 246.6" xmlSpace="preserve">
                                        <path className="step1" d="M0.4,148.2c0,0,83.6-59.5,112.6-97.6s38.5-49.8,68.5-49.8c20,0,11.5,13.8,47.5,14.8c16.6,0.5,36.5,18.2,53,37.1c19.2,22,81.5,95.6,81.5,95.6" />
                                        <path className="step2" d="M0.4,144.5c0,0,41.6-79.6,71.6-116.6s75.6-25,109.5-27C206.6-0.6,194,22.5,203,35.2S233,49,276,49c36,0,46.5,80.2,87.5,95.1" />
                                        <path className="step3" d="M0.4,180c0,0,33.6-42.6,73.6-90.2s72-58.2,86-68.8s21.5-20.1,21.5-20.1S214,40,234,64.4s116.5,88.2,129.5,115.8" />
                                        <polyline className="step4" points="0.4,246 85.1,131.3 148.6,45.5 181.5,0.8 246.6,88.4 364,246.6 " />
                                    </svg>
                                    <Image src={'/images/StepInToParajanovSection/Doll.png'} alt={'gallery pictures'} />
                                </div>
                            </div>
                        </div>
                    </Container>
                </StepInToParajanovStyle>
            </CustomLink>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(StepInToParajanovSection,
    ['winWidth']),
    ['selectedLang'])
