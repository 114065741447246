import styled from 'styled-components'

const SingleNewsStyle = styled.div.attrs({})`
    width: 33.333%;
    position: relative;
    
    &.active {
        &:nth-child(3n-2) {
            &:before{
                transition: var(--trTime) 0.5s linear;
            }
        }

        &:nth-child(3n-1) {
            &:before{
                transition: var(--trTime) 0.8s linear;
            }
        }
        
        &:nth-child(3n) {
            &:before{
                transition: var(--trTime) 1.1s linear;
            }
        }


        &:before  {
            width: 100%;
        }
        
        &:after {
            height: 100%;
        }
        
        .image > span {
            transition: var(--trTime) 1s;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }

    

    &:not(.lastRowItem) {
        &:before {
            content:'';
            position: absolute;
            bottom: 0;
            left:0;
            width: 100%;
            height:1px;
            background-color: ${(props) => props.theme.colors.textColor};
            transition: ${(props) => props.theme.colors.trTime};
            transition-timing-function: linear;
            
        }
    }
    
    &:after {
        content:'';
        position: absolute;
        top:0;
        right:0;
        height: 0;
        width:1px;
        background-color: ${(props) => props.theme.colors.textColor};
        transition: ${(props) => props.theme.colors.trTime};
        transition-timing-function: linear;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        &:before {
            z-index: 1;
        }
    }

    
    a {
        padding:var(--sp6x) var(--sp9x);
        width: 100%;
        height: 100%;
    }
    
    &.type-1 {
        @media screen and (min-width:${(props) => props.theme.mediaQuery.DesktopSizeXS}){
            &:hover {
                .image {
                    border-radius: 50%;
                }
            }
        }
    }

    &.type-2 {
        .image {
            border-radius: 50%;
        }

        @media screen and (min-width:${(props) => props.theme.mediaQuery.DesktopSizeXS}){
            &:hover {
                .image {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    &.type-3 {
        .image{
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
        }

        @media screen and (min-width:${(props) => props.theme.mediaQuery.DesktopSizeXS}){
            &:hover {
                .image {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }


    .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: flex-start;
        flex:1;
    }

    .images {
        width: 58.7%;
        padding-top: 58.7%;
        position: relative;
        margin:var(--sp7x) 0 var(--sp14x);
		
		.load-img img {
			object-fit: contain;
		}

		.load-img {
			position:absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			transform: scale(0.65);
			animation: loadAnimRotate 1.333s infinite cubic-bezier(0.48, 0.04, 0.52, 0.96);
		}

		@keyframes loadAnimRotate {
			0% {
				transform: scale(0.45) rotate(-7deg) translateY(0px);
			}
			
			25% {
				transform: scale(0.45) translateY(-40px);
			}
			
			50% {
				transform: scale(0.45) rotate(7deg) translateY(0px);
			}
			
			75% {
				transform: scale(0.45) translateY(-40px);
			}

			100% {
				transform: scale(0.45) rotate(-7deg);
			}
		}
	}

    .image {
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        transition: var(--trTime);
        
        & > span {
            transition: var(--trTime);
            clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
        }
    }

    .date {
        align-self: flex-end;
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
        a {
            padding:var(--sp5x) var(--sp8x);
        }

        .images {
            width: 61.7%;
            padding-top: 61.7%;
            margin:var(--sp6x) 0 var(--sp11x);
        }
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
        a {
            padding:var(--sp4x) var(--sp7x);
        }

        .images {
            width: 65.7%;
            padding-top: 65.7%;
            margin:var(--sp5x) 0 var(--sp9x);
        }
	}

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
        a {
            padding:var(--sp3x) var(--sp6x);
        }

        .images {
            width: 70.2%;
            padding-top: 70.2%;
            margin:var(--sp4x) 0 var(--sp7x);
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
        width: 50%;

        &.active {
            &:nth-child(3n-2) {
                &:before{
                    transition: unset;
                }
            }
            
            &:nth-child(3n-1) {
                &:before{
                    transition: unset;
                }
            }
            
            &:nth-child(3n) {
                &:before{
                    transition: unset;
                }
            }

            &:nth-child(2n-1) {
                &:before{
                    transition: var(--trTime) 0.5s linear;
                }
            }
            
            &:nth-child(2n) {
                &:before{
                    transition: var(--trTime) 0.8s linear;
                }
            }
        }

        a {
            padding:var(--sp3x) var(--sp4x);
        }

        .images {
            width: 51.8%;
            padding-top: 51.8%;
            margin:var(--sp3x) 0 var(--sp5x);
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
        width: 50%;
        
        a {
            padding:var(--sp2x) var(--sp4x);
        }

        .images {
            width: 57.5%;
            padding-top: 57.5%;
            margin:var(--sp3x) 0 var(--sp5x);
        }
    }

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        width: 100%;
        
        &.active {
            &:nth-child(3n-2) {
                &:before{
                    transition: unset;
                }
            }
            
            &:nth-child(3n-1) {
                &:before{
                    transition: unset;
                }
            }
            
            &:nth-child(3n) {
                &:before{
                    transition: unset;
                }
            }

            &:nth-child(n) {
                &:before{
                    transition: var(--trTime) 0.5s;
                }
            }
        }

        a {
            padding:var(--sp2x);
        }
        
        &:after {
            display: none;
        }

        .images {
            width: 58.7%;
            padding-top: 58.7%;
            margin:var(--sp3x) 0 var(--sp5x);
        }
	}
`
export default SingleNewsStyle
