import React, { memo as Memo, useMemo, useRef, useEffect, useState } from 'react'
import { Image, Container, Text, AnimationWrapp } from 'components/common'
//* HOC's
import { withUIContext, withLanguageContext } from 'context';

//*component
import PartnersStyle from './style'

const Partners = Memo(({ selectedLang, winHeight, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()
    const [activeClass, setActiveClass] = useState('');

    //! Refs
    const titleRef = useRef()
    const titRef = useRef()
    const textRef = useRef()
    const contRef = useRef()

    useEffect(() => {
        if (
            titleRef?.current && titRef?.current, textRef?.current
        ) {
            setSecObj([
                titleRef.current.childNodes,
                titRef.current,
                textRef.current
            ])
        }
    }, [titleRef, titRef, textRef, selectedLang])

    useEffect(() => {

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (contRef?.current[0]?.getBoundingClientRect().y < winHeight * 0.7) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    const storePartners = useMemo(() => {
        return props.data.map((i, k) => {
            return (
                <a href={i.url} target="_blank" className='partner FlexBoxColumn' key={k}>
                    <Image src={i.logo} alt='partner-logo' />
                    <div className='overflowHidden'>
                        <Text tag={'p'} className={'description uppercase pM GHEAMariamBold'} text={i.name} />
                    </div>
                </a>
            )
        })
    }, [props?.partners])

    return (
        <AnimationWrapp state={secObj}>
            <PartnersStyle>
                <Container ref={contRef} className={`FlexBoxColumn containerPaddBottom ${activeClass}`}>
                    <div className='top FlexBox spaceBetween'>
                        <Text ref={titleRef} tag={'stringToLetters'} className={'title h3 GHEAMariamBold uppercase'} text={'ourPartners'} />
                        <div className='general-partner FlexBoxColumn'>
                            <Image src={props.genPartnerLogo} alt='general-partner-logo' />
                            <div className='overflowHidden FlexBox'>
                                <Text ref={titRef} tag={'p'} className={'uppercase title pM GHEAMariamBold'} text={props.genPartnerTitle} />
                                &nbsp;&nbsp;
                                <Text ref={titRef} tag={'p'} className={'uppercase title pM GHEAMariamBold'} text={'foundation'} />
                            </div>
                            <div className='overflowHidden'>
                                <Text ref={textRef} tag={'p'} className={'description pXS NotoSansRegular'} text={props.genPartnerDescription} />
                            </div>
                        </div>
                    </div>
                    <div className='bottom FlexBox'>
                        {storePartners}
                    </div>
                </Container>
            </PartnersStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(Partners,
    ['winHeight']),
    ['selectedLang']);
