import React, { useRef, useImperativeHandle, forwardRef, } from 'react';
import { Image } from 'components/common'


const ImagesContainer = forwardRef((props, ref) => {

    //!Ref
    const circleRef = useRef()

    useImperativeHandle(ref, () => circleRef.current, [])

    return (
        <div className="imagesWrapper" ref={circleRef}>
            <div className="imagesCont">

                <Image className="image1" src={'/images/SupportSection/imag6-min.png'} alt={'gallery image'} />

                <Image className="image2" src={'/images/SupportSection/imag2-min.png'} alt={'gallery image'} />

                <Image className="image3" src={'/images/SupportSection/imag3-min.png'} alt={'gallery image'} />

                <Image className="image4" src={'/images/SupportSection/imag4-min.png'} alt={'gallery image'} />

                <Image className="image5" src={'/images/SupportSection/imag5-min.png'} alt={'gallery image'} />

                <Image className="image6" src={'/images/SupportSection/imag6-min.png'} alt={'gallery image'} />

                <Image className="image7" src={'/images/SupportSection/imag7-min.png'} alt={'gallery image'} />

                <Image className="image8" src={'/images/SupportSection/imag8-min.png'} alt={'gallery image'} />

                <Image className="image9" src={'/images/SupportSection/imag9-min.png'} alt={'gallery image'} />

                <Image className="image10" src={'/images/SupportSection/imag10-min.png'} alt={'gallery image'} />

                <Image className="image11" src={'/images/SupportSection/imag11-min.png'} alt={'gallery image'} />

                <Image className="image12" src={'/images/SupportSection/imag12-min.png'} alt={'gallery image'} />

                <Image className="image13" src={'/images/SupportSection/imag13-min.png'} alt={'gallery image'} />

                <Image className="image14" src={'/images/SupportSection/imag14-min.png'} alt={'gallery image'} />

                <Image className="image15" src={'/images/SupportSection/imag15-min.png'} alt={'gallery image'} />

                <Image className="image16" src={'/images/SupportSection/imag16-min.png'} alt={'gallery image'} />

                <Image className="image17" src={'/images/SupportSection/imag17-min.png'} alt={'gallery image'} />

                <Image className="image18" src={'/images/SupportSection/imag18-min.png'} alt={'gallery image'} />

                <Image className="image19" src={'/images/SupportSection/imag19-min.png'} alt={'gallery image'} />

                <Image className="image20" src={'/images/SupportSection/imag20-min.png'} alt={'gallery image'} />

                <Image className="image21" src={'/images/SupportSection/imag21-min.png'} alt={'gallery image'} />

                <Image className="image22" src={'/images/SupportSection/imag22-min.png'} alt={'gallery image'} />

                <Image className="image23" src={'/images/SupportSection/imag23-min.png'} alt={'gallery image'} />

                <Image className="image24" src={'/images/SupportSection/imag24-min.png'} alt={'gallery image'} />

                <Image className="image25" src={'/images/SupportSection/imag25-min.png'} alt={'gallery image'} />

                <Image className="image26" src={'/images/SupportSection/imag26-min.png'} alt={'gallery image'} />

                <Image className="image27" src={'/images/SupportSection/imag27-min.png'} alt={'gallery image'} />

                <Image className="image28" src={'/images/SupportSection/imag28-min.png'} alt={'gallery image'} />

                <Image className="image29" src={'/images/SupportSection/imag29-min.png'} alt={'gallery image'} />

                <Image className="image30" src={'/images/SupportSection/imag30-min.png'} alt={'gallery image'} />

                <Image className="image31" src={'/images/SupportSection/imag31-min.png'} alt={'gallery image'} />

                <Image className="image32" src={'/images/SupportSection/imag32-min.png'} alt={'gallery image'} />

                <Image className="image33" src={'/images/SupportSection/imag33-min.png'} alt={'gallery image'} />

                <Image className="image34" src={'/images/SupportSection/imag2-min.png'} alt={'gallery image'} />

                <Image className="image35" src={'/images/SupportSection/imag17-min.png'} alt={'gallery image'} />

                <Image className="image36" src={'/images/SupportSection/imag18-min.png'} alt={'gallery image'} />

                <Image className="image37" src={'/images/SupportSection/imag2-min.png'} alt={'gallery image'} />

                <Image className="image38" src={'/images/SupportSection/imag3-min.png'} alt={'gallery image'} />
            </div>

        </div>

    )
})

export default ImagesContainer