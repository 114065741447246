import React, { memo as Memo, useState, useRef, useEffect } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { AnimationWrapp, Text, Image, Container } from 'components/common'


//* Styles
import ImgTextCompStyle from './style'


const ImgTextComp = Memo(({ winWidth, winHeight, selectedLang, ...props }) => {
    const titleTopRef = useRef();
    const titleRef = useRef();
    const textRef = useRef();
    const ref = useRef();

    //! States
    const [secObj, setSecObj] = useState()
    const [activeClass, setActiveClass] = useState('');

    useEffect(() => {
        if (
            titleRef?.current
        ) {
            setSecObj([
                [titleTopRef.current, titleRef.current.firstChild,
                textRef.current.firstChild]
            ])
        }
    }, [titleRef, selectedLang])


    useEffect(() => {

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.5) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    return (
        <AnimationWrapp state={secObj}>
            <ImgTextCompStyle ref={ref} className={`FlexBoxColumn ${props.landscapeImg ? 'landscape' : ""}`} >
                <Container className='FlexBox containerPaddTopBottom'>
                    <Image className={`${props.landscapeImg ? 'landscape' : ''} ${activeClass}`} src={props.image} alt='featured_image' />
                    <div className='info FlexBoxColumn'>
                        <span ref={titleTopRef} />
                        <div ref={titleRef} className='title-cont overflowHidden margBottomTitle'>
                            <Text tag={'h5'} className={'description h5 GHEAMariamRegular'} text={props.data.title} />
                        </div>
                        <div ref={textRef} className='text-cont overflowHidden'>
                            <Text tag={'p'} className={'fromLeft pM NotoSansRegular'} text={props.data.text} />
                        </div>
                    </div>
                </Container>
            </ImgTextCompStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(ImgTextComp,
    ['winWidth', 'winHeight']),
    ['selectedLang']);