import React, { memo as Memo, useState, useRef, useEffect, useMemo } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context';

//* Components
import { Image, Text, ShareIcon, Container, AnimationWrapp, Icon } from 'components/common'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'

import SwiperCore, {
    EffectFade, Navigation, Pagination
} from 'swiper';
// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination]);

//* Style
import PublicationsStyle from './style'

const Publications = Memo(({ winWidth, winHeight, selectedLang, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()
    const [activeClass, setActiveClass] = useState('');

    //! Refs
    const titleRef = useRef()
    const slRef = useRef()

    useEffect(() => {
        if (
            titleRef?.current
        ) {
            setSecObj([
                titleRef.current.childNodes,
            ])
        }
    }, [titleRef, selectedLang])


    useEffect(() => {

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (slRef?.current?.getBoundingClientRect().y < winHeight) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    const storePhotos = useMemo(() => {
        return props.data.map((i, k) => {
            return (
                <SwiperSlide className='FlexBox' key={k}>
                    <Image src={i.featured_image} />
                    <div className='info FlexBoxColumn  spaceBetween'>
                        <div className='top'>
                            <div className='overflowHidden'>
                                <Text tag={'p'} className={'description pub-title p GHEAMariamRegular'} text={i.title} />
                            </div>
                            <div className='overflowHidden'>
                                <Text tag={'p'} className={'description pXS NotoSansRegular'} text={i.description} />
                            </div>
                        </div>
                        {i.pdf && <div className='bottom'>
                            <a target='_blank' href={i.pdf} className='pM NotoSansRegular FlexBox alignMiddle uppercase' download>
                                <span>{props.translate('DownloadPdf')}</span>
                                <Icon className='icon-arrowTop pS' />
                            </a>
                        </div>
                        }
                    </div>
                </SwiperSlide>
            )
        })
    }, [props.data])

    return (
        <AnimationWrapp state={secObj}>
            <PublicationsStyle className='FlexBoxColumn Publications'>
                <Container className="containerPaddBottom">
                    <div className='tit overflowHidden margBottomTitle'>
                        <Text tag={'stringToLetters'}
                            className={'title h3 GHEAMariamBol uppercase'}
                            text={'ourPublishments'}
                            ref={titleRef} />
                    </div>
                    <Swiper
                        ref={slRef}
                        spaceBetween={winWidth > 1279 ? 32 : 24}
                        speed={300}
                        slidesPerView={winWidth > 1279 ? 2.5 : winWidth > 480 ? 1.8 : 1}
                        navigation={{
                            nextEl: '.arr-right',
                            prevEl: '.arr-left',
                        }}
                        pagination={{
                            "type": "progressbar"
                        }}
                        className={`mySwiper ${activeClass}`}
                    >
                        {storePhotos}
                        <ShareIcon parentClass={'arr-left'} className='icon-arrowTop ' />
                        <ShareIcon parentClass={'arr-right'} className='icon-arrowTop' />
                    </Swiper>
                </Container>
            </PublicationsStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(Publications,
    ['winWidth', 'winHeight']),
    ['translate', 'selectedLang'])
