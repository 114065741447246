import React, { memo as Memo, useState, useRef, useEffect } from 'react'
import { withUIContext, withLanguageContext } from 'context'

//* Helpers
import { arrayGenChild } from 'helpers'
import { gsap } from 'gsap'

//* Components
import { Icon, Title, AnimationWrapp, Text, Image, Container } from 'components/common'

//* Styles
import HeroSectionAboutStyle from './style'


const HeroSectionAbout = Memo(({ winWidth, winHeight, selectedLang, ...props }) => {

    //! Refs
    const titleRef = useRef()
    const imgRef = useRef()
    const textTopRef = useRef()
    const parentRef = useRef()
    const quoteRef = useRef()
    const autorRef = useRef()
    const estRef1 = useRef()
    const estRef2 = useRef()

    //! States
    const [secObj, setSecObj] = useState()

    useEffect(() => {
        if (titleRef?.current && parentRef?.current && estRef1?.current && quoteRef?.current && autorRef?.current) {
            setSecObj([
                [arrayGenChild(titleRef.current[0]), estRef1.current, estRef2.current],
                [parentRef.current.childNodes[0].firstChild, parentRef.current.childNodes[1].firstChild, parentRef.current.childNodes[2].firstChild],
                [textTopRef.current, quoteRef.current, autorRef.current],
            ])
        }
    }, [titleRef, autorRef, quoteRef, estRef1, parentRef, selectedLang])

    const move = (event) => {
        if (winWidth > 1279) {
            let pos = event.clientX
            let diff = pos - winWidth / 2

            gsap.to(imgRef.current, { x: diff / -5, xPercent: -50 })
        }
    }

    return (
        <AnimationWrapp state={secObj}>
            <HeroSectionAboutStyle className='FlexBoxColumn' onMouseMove={(e) => move(e)}>
                <Container className='FlexBoxColumn containerPaddTop containerPaddBottom'>
                    <Image ref={imgRef} className='bg-img' src='/images/AboutMuseum/aboutMuseumBg.png' alt='background-floating-photo' />
                    <div className='heading'>
                        <Title
                            className={'justifyEnd '}
                            ref={titleRef}
                            title={{
                                1: [{ type: 'big', name: 'aboutMuseumLineFirst' }, { type: 'icon' }],
                                2: [{ type: 'big', name: 'aboutMuseumLineSecond' }],
                            }}
                        />
                        <div className='overflowHidden est FlexBoxColumn'>
                            <Text ref={estRef1} tag={'p'} className={'description uppercase pL NotoSansRegular'} text={'est'} />
                            <Text ref={estRef2} tag={'p'} className={'description uppercase pL NotoSansRegular'} text={'founderZaven'} />
                        </div>
                    </div>
                    <div ref={parentRef} className='texts FlexBoxColumn'>
                        <div className='overflowHidden'>
                            <Text tag={'p'} className={'description text pM NotoSansRegular'} text={props.data.text1} />
                        </div>
                        <div className='overflowHidden'>
                            <Text tag={'p'} className={'description text pM NotoSansRegular'} text={props.data.text2} />
                        </div>
                        <div className='overflowHidden'>
                            <Text tag={'p'} className={'description text pM NotoSansRegular'} text={props.data.text3} />
                        </div>
                    </div>
                    <div className='overflowHidden quote-block FlexBoxColumn'>
                        <span ref={textTopRef} />
                        <Text ref={quoteRef} tag={'p'} className={'description quote p NotoSansRegular'} text={props.data.quote} />
                        <div ref={autorRef} className='description autor FlexBox alignMiddle'>
                            <Icon className='icon-star2 h6' />
                            <Text tag={'p'} className={'name pL GHEAMariamBold uppercase'} text={props.data.quoteAuthor} />
                        </div>
                    </div>
                </Container>
            </HeroSectionAboutStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withUIContext(HeroSectionAbout,
    ['winWidth', 'winHeight']),
    ['selectedLang'])
