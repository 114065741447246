import styled from 'styled-components'

const FormStyle = styled.div.attrs({})`
	--inputColPadding: var(--sp2x);
	--inputPaddingInTop: var(--sp2-5x);
	--inputPaddingInLeft: var(--sp5x);
	--inputMarginBottom: var(--sp4x);
	--FormColWidth: calc(50% - var(--inputColPadding));
	--FormColWidth-30: calc(33% - var(--inputColPadding));
	--textareaMinHeight: var(--sp31x);
	--antCheckboxSize: var(--sp3-5x);
	--antCheckboxIconMarg: var(--sp2x);
	--radioButtonMarg: var(--sp1-5x);
	--radioButtonInnerSize: var(--sp3x);

	--minWidth1: var(--sp58x);
	--paddingTop: var(--sp2-8x);
	--paddingLeft: var(--sp15x);
	--marginBottom: var(--sp9x);

	--circleInputPadd: var(--sp2-5x);
	--arrowSize: var(--spSize);

	.FormRow {
		max-width: 100%;

		.FormColGroup {
			width: 100%;
			margin-bottom: var(--titleMarg);

			.ant-form-item-label {
				font-size: var(--p);
				line-height: var(--lineHeight);
				font-weight: unset;
				text-transform: uppercase;
				font-family: NotoSansRegular;
				margin-bottom: var(--inputMarginBottom);
			}
			.ant-form-item-label {
				overflow: hidden;
				label {
					display: inline-block;
					transform: translateY(140%);
				}
			}

			.colItems {
				padding-right: var(--inputMarginBottom);
				margin-bottom: var(--inputMarginBottom);
				&:first-child {
					padding-left: 0;
				}

				.ant-radio-button-wrapper,
				.ant-form-item-label {
					transform: translateY(140%);
					height: auto;
				}

				&.other {
					.ant-radio-button-wrapper {
						> span:not(.ant-radio-button) {
							padding: 0;
						}
					}
				}
			}

			.ant-radio-button-wrapper {
				min-width: var(--sp21x);
				padding: var(--spSize);
				border-radius: 50%;
				border: 1px solid var(--textColor);
				position: relative;
				display: block;
				transition: border var(--trTime) ease;
				> span:not(.ant-radio-button) {
					text-align: center;
					padding: var(--circleInputPadd) var(--sp2x);
					display: block;
					border-radius: 50%;
					background-color: var(--textColor);
					color: var(--backColor);
					font-family: NotoSansRegular;
					font-size: var(--pS);
					cursor: pointer;
				}

				.ant-radio-button-inner {
					display: none;
				}

				.mutableFormItem {
					max-width: var(--sp17x);
					display: inline-block;
					&:after {
						content: '+';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						color: var(--backColor);
						transition: all var(--trTime2) ease;
						opacity: 1;
					}
					.ant-input {
						opacity: 0;
						pointer-events: none;
						transition: all var(--trTime2) ease;
						padding: 0 var(--circleInputPadd);
						height: calc((var(--circleInputPadd) * 2) + (var(--pS) * var(--lineHeight)));
						width: 100%;
						color: var(--backColor);
						font-family: NotoSansRegular;
						font-size: var(--pS);
						line-height: var(--lineHeight);
						border: 0;
						background-color: transparent;

						&::-webkit-input-placeholder {
							color: var(--backColor);
							font-family: NotoSansRegular;
							font-size: var(--pS);
						}
						&::-webkit-input-placeholder {
							color: var(--backColor);
							font-family: NotoSansRegular;
							font-size: var(--pS);
						}
						&::-moz-placeholder {
							color: var(--backColor);
							font-family: NotoSansRegular;
							font-size: var(--pS);
						}
						&:-ms-input-placeholder {
							color: var(--backColor);
							font-family: NotoSansRegular;
							font-size: var(--pS);
						}
						&::placeholder {
							color: var(--backColor);
							font-family: NotoSansRegular;
							font-size: var(--pS);
						}
					}
				}
				.ant-radio-button {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					input {
						cursor: pointer;
						width: 100%;
						height: 100%;
						padding: 0;
						margin: 0;
					}
				}

				&.ant-radio-button-wrapper-checked {
					border: 1px solid transparent;

					.mutableFormItem {
						.ant-input {
							opacity: 1;
							pointer-events: all;
						}
						&:after {
							opacity: 0;
							visibility: hidden;
						}
					}
				}
			}
		}

		.FormCol {
			width: 100%;
			margin-bottom: var(--inputMarginBottom);

			&.FormCol-50 {
				max-width: var(--FormColWidth);
				display: flex;
				flex-direction: column;
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}

			&.FormCol-30 {
				max-width: var(--FormColWidth-30);
				display: flex;
				flex-direction: column;
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}

			&.marginNone {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.ant-input,
			.ant-select-selector {
				width: 100%;
				height: auto;
				padding: var(--inputPaddingInTop) var(--inputPaddingInLeft);
				font-family: NotoSansRegular;
				font-size: var(--pM);
				line-height: var(--lineHeight);
				color: var(--textColor);
				border: 1px solid var(--textColor);
				border-radius: var(--sp5x);
				background-color: transparent;

				&::-webkit-input-placeholder {
					color: var(--textColor);
					font-family: NotoSansRegular;
					font-size: var(--pM);
				}
				&::-webkit-input-placeholder {
					color: var(--textColor);
					font-family: NotoSansRegular;
					font-size: var(--pM);
				}
				&::-moz-placeholder {
					color: var(--textColor);
					font-family: NotoSansRegular;
					font-size: var(--pM);
				}
				&:-ms-input-placeholder {
					color: var(--textColor);
					font-family: NotoSansRegular;
					font-size: var(--pM);
				}
				&::placeholder {
					color: var(--textColor);
					font-family: NotoSansRegular;
					font-size: var(--pM);
				}
			}

			.ant-input:focus,
			.ant-input-focused {
				border-color: var(--textColor) !important;
				box-shadow: none !important;
			}

			.ant-radio-group {
				width: 100%;
				.FormCol {
					margin-bottom: 0 !important;
				}

				.ant-radio-button-wrapper {
					background: transparent;
					cursor: pointer;
					position: relative;
					display: block;
					border: 0 !important;
					border-radius: 0 !important;
					margin: var(--radioButtonMarg) 0;
					line-height: var(--lineHeight);
					padding: 0 var(--sp4x);
					height: auto;

					.ant-radio-button {
						color: var(--textColor);
						font-size: var(--pM);
						line-height: var(--lineHeightXS);
						font-family: NotoSansRegular;
						z-index: 1;
						&.ant-radio-button-checked {
							color: var(--textColor);
							.ant-radio-button-inner {
								&:after {
									opacity: 1;
								}
							}
						}
						.ant-radio-button-inner {
							border-radius: var(--sp5x);
							border: 1px solid var(--textColor);
							width: var(--radioButtonInnerSize);
							height: var(--radioButtonInnerSize);
							position: absolute;
							transform: translateY(-50%);
							left: 0;
							top: var(--radioButtonMarg);

							&:after {
								opacity: 0;
								content: '';
								border-radius: var(--sp5x);
								background-color: var(--textColor);
								width: 14px;
								height: 14px;
								position: absolute;
								transform: translate(-50%, -50%);
								left: 50%;
								top: 50%;
							}
						}
					}

					span {
						> div {
							margin-top: var(--spSize);
						}
					}
					&.ant-radio-button-wrapper-checked,
					&:hover {
						color: var(--textColor);
						background: transparent;
						border: none !important;
						box-shadow: none;
					}
					&:before {
						content: none;
					}
				}
			}
		}

		.ant-select {
			position: relative;
		}

		.ant-select.ant-select-multipme {
			.ant-select-selector {
				flex: auto;
				padding: 0 !important;
				min-height: var(--sp6-5x);
				.ant-select-selection-item {
					margin: var(--spSize) var(--sp2x) var(--spSize) var(--spSize) !important;
					height: auto;
					padding: calc(var(--spSize) - 2px) var(--spSize) !important ;
					.ant-select-selection-item-content {
						margin-right: var(--sp2x);
						line-height: var(--lineHeightXS);
					}
					.ant-select-selection-item-remove {
						display: flex;
						align-items: center;
						.anticon-close {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}

		.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
			border-color: var(--textColor) !important;
		}
		.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-color: var(--textColor) !important;
			box-shadow: none !important;
		}

		.ant-col {
			position: relative;
		}

		textarea {
			min-height: var(--textareaMinHeight);
			resize: none;
			-ms-overflow-style: none;
			::-webkit-scrollbar {
				display: none;
			}
		}

		.ant-form-item {
			margin-bottom: var(--sp3x);
			&:last-child {
				margin-bottom: 0;
			}
			& ~ .warningWrapp {
				margin-top: calc(var(--spSize) * -1);
			}
		}

		.ant-input-group-wrapper {
			.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
				border-color: transparent !important;
			}
			.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
				border-color: transparent !important;
			}
			.ant-select-selector {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}

		.ant-input-group-addon {
			padding: var(--inputPaddingInTop) var(----inputPaddingInLeft);
			font-family: NotoSansRegular;
			font-size: var(--pM);
			color: var(--textColor);
			border: 1px solid var(--textColor);
			border-radius: var(--sp5x);
			background-color: transparent;

			.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
				border-color: transparent !important;
			}
		}

		.ant-input-affix-wrapper {
			padding: 0;
			background-color: transparent;
			border: 0;
			&:focus {
				box-shadow: none !important;
			}
		}
		.ant-input-affix-wrapper-focused {
			box-shadow: none !important;
		}

		.checkBox {
			.ant-checkbox-wrapper {
				display: inline-flex;
				align-items: center;
				font-family: NotoSansRegular;
				font-size: var(--pM);
				color: var(--textColor);
				cursor: pointer;

				.ant-checkbox {
					border-radius: var(--sp0-75x);
					min-width: calc(var(--antCheckboxSize) + var(--sp0-5x));
					width: calc(var(--antCheckboxSize) + var(--sp0-5x));
					height: calc(var(--antCheckboxSize) + var(--sp0-5x));
					.ant-checkbox-inner {
						background-color: transparent;
						width: 31%;
						height: 67%;
						border-radius: 1px;
						border-bottom: 2px solid var(--textColor);
						border-right: 2px solid var(--textColor);
						transform: translate(-50%, -60%) rotate(45deg);
					}
				}
			}
		}

		.ant-checkbox-wrapper {
			display: inline-flex;
			align-items: center;
			font-family: NotoSansRegular;
			font-size: var(--pM);
			color: var(--textColor);
			cursor: pointer;

			.ant-checkbox {
				border: 1px solid var(--textColor);
				border-radius: var(--sp5x);
				width: var(--antCheckboxSize);
				height: var(--antCheckboxSize);
				margin-right: var(--antCheckboxIconMarg);
				overflow: hidden;
				position: relative;
				display: inline-block;

				&.ant-checkbox-checked {
					.ant-checkbox-inner {
						opacity: 1;
					}
				}

				.ant-checkbox-input {
					position: absolute;
				}
				.ant-checkbox-inner {
					display: inline-block;
					background-color: var(--textColor);
					position: absolute;
					top: 50%;
					left: 50%;
					width: 70%;
					height: 70%;
					border-radius: var(--sp5x);
					opacity: 0;
					transition: all var(--trTime2) ease;
					transform: translate(-50%, -50%);
				}
			}
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
			content: none;
		}

		.ant-select-clear {
			display: none;
		}

		.ant-select-selection-item {
			line-height: var(--lineHeight);
			color: var(--textColor);
			font-family: NotoSansRegular;
			font-size: var(--pM);
		}

		.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
			line-height: var(--lineHeight);
			color: var(--textColor);
			font-family: NotoSansRegular;
			font-size: var(--pM);
			&:after {
				height: 0;
			}
		}

		.ant-select-arrow {
			right: var(--inputPaddingInLeft) !important;
			width: auto;
			height: auto;
			.icon-arrow-down {
				&:before {
					font-size: var(--arrowSize);
				}
				&:after {
					content: '';
					height: calc(var(--inputPaddingInTop) * 2);
					position: absolute;
					left: calc(-1 * var(--inputPaddingInLeft));
					top: 50%;
					transform: translateY(-50%);
					width: 1px;
					background-color: var(--textColor);
				}
			}
		}

		.ant-row {
			width: 100%;
		}

		.ant-form-item-children-icon {
			display: none;
		}

		.ant-form-item-expmain {
			color: var(--errColor);
			margin-top: var(--spSize);
			padding: 0 var(--inputPaddingInLeft);
			font-family: NotoSansRegular;
			font-size: var(--pS);
		}

		.ant-form-item-explain {
			color: var(--texColor) !important;
			font-size: var(--pS);
			font-family: NotoSansRegular;
			margin-top: var(--sp2x);
			padding-left: var(--inputPaddingInLeft);
		}

		.ant-form-item-has-error {
			.ant-input,
			.ant-select-selector {
				background-color: transparent !important;
				border: 1px solid var(--errColor) !important;
				color: var(--errColor) !important;

				&::-webkit-input-placeholder {
					color: var(--errColor);
				}
				&::-webkit-input-placeholder {
					color: var(--errColor);
				}
				&::-moz-placeholder {
					color: var(--errColor);
				}
				&:-ms-input-placeholder {
					color: var(--errColor);
				}
				&::placeholder {
					color: var(--errColor);
				}
			}

			.ant-checkbox-wrapper {
				color: var(--errColor);
				a {
					color: var(--errColor);
				}
				.ant-checkbox {
					border: 1px solid var(--errColor);
				}

				.ant-checkbox-inner {
					border-bottom: 2px solid var(--errColor) !important;
					border-right: 2px solid var(--errColor) !important;
				}

				&::-webkit-input-placeholder {
					color: var(--errColor);
				}
				&::-webkit-input-placeholder {
					color: var(--errColor);
				}
				&::-moz-placeholder {
					color: var(--errColor);
				}
				&:-ms-input-placeholder {
					color: var(--errColor);
				}
				&::placeholder {
					color: var(--errColor);
				}
			}

			.ant-form-item-explain,
			.ant-form-item-explain-error {
				color: var(--errColor) !important;
			}

			.ant-input-group-addon {
				.ant-select-selector {
					border-color: transparent !important;
				}
			}
		}

		.bottomText {
			margin-top: var(--bottomTextMarginTop);
		}
	}

	.btn-cont {
		width: 100%;
		margin-top: var(--marginBottom);

		.btn {
			-webkit-appearance: none;
			cursor: pointer;
			display: inline-flex;
			justify-content: center;
			color: var(--backColor);
			line-height: var(--lineHeightXS);
			position: relative;
			z-index: 1;
			border: 0;
			height: auto;
			font-size: var(--pM);
			overflow: hidden;
			background-color: transparent;
			transition: opacity var(--trTime2) ease;

			&.disabled {
				opacity: 0.5 !important;
				cursor: auto;
				pointer-events: none;
			}

			&.btnAnimation {
				padding: var(--paddingTop) var(--paddingLeft);
				min-width: var(--minWidth1);
				> * {
					pointer-events: none;
				}
				.LottieWrapp {
					position: absolute;
					top: 1px;
					bottom: -1px;
					z-index: -1;
					&:nth-child(1) {
						left: 0;
						&:after {
							right: -2px;
							left: 88%;
							height: 100%;
							width: 600%;
						}
					}
					&:nth-child(2) {
						right: 0;
						transform: rotate(180deg);
						&:after {
							right: -2px;
							left: 88%;
							height: 100%;
							width: 600%;
						}
					}
					&:after {
						content: '';
						background-color: var(--textColor);
						position: absolute;
						top: 0;
						bottom: 0;
					}

					svg {
						width: auto !important;
						path {
							fill: var(--textColor);
						}
					}
				}

				&.reverseColor {
					color: var(--textColor);
					.LottieWrapp {
						svg {
							path {
								fill: var(--backColor);
							}
						}
						&:after {
							background-color: var(--backColor);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--inputPaddingInTop: var(--sp2x);
		--inputPaddingInLeft: var(--sp4x);
		--textareaMinHeight: var(--sp25x);
		--antCheckboxSize: var(--sp3x);
		--antCheckboxIconMarg: var(--sp1-5x);

		--minWidth1: var(--sp54x);
		--marginBottom: var(--sp8x);
		--paddingTop: var(--sp3x);
		--paddingLeft: var(--sp8x);
		--circleInputPadd: var(--sp1-5x);
		--arrowSize: 7px;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--inputPaddingInTop: var(--sp1-5x);
		--inputPaddingInLeft: var(--sp3x);
		--textareaMinHeight: var(--sp23x);
		--inputMarginBottom: var(--sp3x);
		--inputColPadding: var(--sp1-5x);
		--antCheckboxSize: var(--sp2-5x);
		--antCheckboxIconMarg: var(--spSize);

		--minWidth1: var(--sp48x);
		--marginBottom: var(--sp7x);

		--circleInputPadd: var(--sp1-5x);
		--arrowSize: 5px;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--textareaMinHeight: var(--sp22x);
		--inputMarginBottom: var(--sp3x);
		--inputColPadding: var(--sp1-5x);
		--antCheckboxSize: var(--sp2-5x);
		--antCheckboxIconMarg: var(--spSize);

		--minWidth1: var(--sp43x);
		--marginBottom: var(--sp5x);
		--circleInputPadd: var(--sp1-5x);
		--arrowSize: 5px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--inputPaddingInTop: var(--sp1-5x);
		--inputPaddingInLeft: var(--sp3x);
		--textareaMinHeight: var(--sp22x);
		--inputMarginBottom: var(--sp3x);
		--inputColPadding: var(--sp1-5x);
		--antCheckboxSize: var(--sp2-5x);
		--antCheckboxIconMarg: var(--spSize);

		--paddingTop: var(--sp2x);
		--paddingLeft: var(--sp4x);
		--minWidth1: var(--sp36x);
		--marginBottom: var(--sp4x);
		--circleInputPadd: var(--sp1-5x);
		--arrowSize: 5px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--marginBottom: var(--sp3x);
		--FormColWidth: 100%;
		--FormColWidth-30: 100%;
		--circleInputPadd: var(--sp1-5x);

		.FormRow {
			.ant-input {
				font-size: 16px;
			}
		}
	}
`
export default FormStyle
