import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Config
import { arrayGen } from 'helpers'

//* Components
import KaleidoscopeModule from '../KaleidoscopeModule'
import { Text, Icon, Container, AnimationWrapp, Image } from 'components/common'

//* Style
import KaleidoscopeSectionStyle from './style'

const KaleidoscopeSection = Memo(({ selectedLang, description, author, winWidth, screenSizes, isSafari, ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [secTopObj, setSecTopObj] = useState()
	const [startAnimation, setStartAnimation] = useState(false)

	//! Refs
	const iconRef = useRef()
	const textRef = useRef()
	const nameRef = useRef()
	const contRef = useRef()

	useEffect(() => {
		if (iconRef?.current && textRef?.current && nameRef?.current && contRef?.current) {
			setSecTopObj(contRef.current[0])
			setSecObj([arrayGen([iconRef.current, textRef.current.firstChild, nameRef.current.firstChild])])
		}
	}, [iconRef, textRef, nameRef, contRef, selectedLang])

	const StartAnim = (x) => {
		if (x && !startAnimation) {
			setStartAnimation(true)
		} else {
			setStartAnimation(false)
		}
	}

	return (
		<AnimationWrapp animationDelay={0.3} state={secObj} sectionTop={secTopObj} sectionAnimationStart={(e) => StartAnim(e)}>
			<KaleidoscopeSectionStyle className='relativeWrapp FlexBox alignMiddle overflowHidden'>
				<Container className='FlexBox alignCenter containerPaddTopBottom' ref={contRef}>
					<div className={`infoWrappere overflowHidden FlexBoxColumn spaceBetween alignMiddle ${startAnimation ? 'active' : ''}`}>
						<span ref={iconRef}>
							<Icon className='icon-star2' />
						</span>
						<div ref={textRef} className='overflowHidden'>
							<Text tag={'p'} className={'description pL NotoSansRegular'} text={description} />
						</div>
						<div ref={nameRef} className='overflowHidden'>
							<Text tag={'p'} className={'description pS NotoSansRegular uppercase'} text={author} />
						</div>
					</div>
				</Container>
				{winWidth >= 1024 && !isSafari ? <KaleidoscopeModule /> : <Image src={'/images/kaleidoscopeMobile.jpg'} alt={'Kaleidoscope Image'} />}
			</KaleidoscopeSectionStyle>
		</AnimationWrapp>
	)
})

export default withUIContext(withLanguageContext(KaleidoscopeSection, ['selectedLang']), ['winWidth', 'screenSizes'])
