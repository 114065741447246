import styled from 'styled-components'

const PublicationsStyle = styled.div.attrs((props) => ({ className: props.className }))`
	padding: var(--containerMarginTop) 0;
	padding-bottom: var(--sp9x);

	.title {
		max-width: var(--sp115x);
	}

	.swiper-pagination-progressbar {
		background-color: var(--textColor);
	}

	.swiper-pagination {
		top: unset;
		bottom: calc(-1 * var(--sp9x));
		height: 1px;

		span {
			height: 3px;
			background-color: var(--textColor);
			top: -1px;
		}
	}

	.swiper-container {
		overflow: visible;

		&.active {
			.swiper-wrapper {
				.swiper-slide {
					overflow: hidden;

					.info {
						.top {
							.overflowHidden {
								.description {
									transform: translateY(0);
								}
							}
						}
						.bottom {
							i {
								transform: rotate(180deg);
								margin-left: var(--sp2x);
							}
						}
					}

					@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
						.info .bottom {
							transform: translateY(300%);
							transition: all var(--trTime);
						}

						&:hover {
							.info .bottom {
								transform: translateY(0);
							}
						}
					}

					.imageCont {
						width: 39.4%;
						padding-top: 51.7%;

						img {
							clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
						}
					}
				}
			}
		}

		.socIconItem {
			z-index: 2;
			display: inline;
			position: absolute;
			bottom: calc(100% + var(--sp7x));
			cursor: pointer;

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
				&:after {
					pointer-events: none;
				}

				&:hover {
					&:after {
						animation: slideActionButtonAnim var(--trTime) ease-in;
					}
				}

				@keyframes slideActionButtonAnim {
					0% {
						transform: translateX(-50%) translateY(-50%) rotate(36deg);
					}
					50% {
						transform: translateX(-50%) translateY(-50%) rotate(20deg);
					}
					100% {
						transform: translateX(-50%) translateY(-50%) rotate(36deg);
					}
				}
			}

			i {
				display: inline-block;
			}

			&.arr-left {
				right: var(--shareLinkRight);

				i {
					transform: rotate(-90deg);
				}
			}

			&.arr-right {
				right: 0;

				i {
					transform: rotate(90deg);
				}
			}
		}

		.swiper-wrapper {
			.swiper-slide {
				.imageCont {
					width: 39.4%;
					padding-top: 51.7%;

					img {
						transition: var(--trTime);
						clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
					}
				}

				.info {
					flex: 1;
					margin-left: var(--sp4x);

					.top {
						.overflowHidden {
							.description {
								transition: var(--trTime);
							}
						}
					}

					.pub-title {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		padding-bottom: var(--sp6x);

		.title {
			max-width: var(--sp93x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp6x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp6x));

				&.arr-left {
					right: var(--shareLinkRight);
				}
			}

			.swiper-wrapper {
				.swiper-slide {
					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp3x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		padding-bottom: var(--sp5x);

		.title {
			max-width: var(--sp67x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp5x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp5x));
			}

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						width: 45.4%;
						padding-top: 59.7%;
					}

					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp3x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		padding-bottom: var(--sp4x);

		.title {
			max-width: var(--sp61x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp4x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp4x));
			}

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						width: 45.4%;
						padding-top: 59.7%;
					}

					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp2x);
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		padding-bottom: var(--sp3x);

		.title {
			max-width: var(--sp45x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp3x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp3x));
			}

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						width: 40%;
						padding-top: 52.4%;
					}

					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp2x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding-bottom: var(--sp3x);

		.title {
			max-width: var(--sp45x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp3x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp3x));
			}

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						width: 40%;
						padding-top: 52.4%;
					}

					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp2x);
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding-bottom: var(--sp3x);

		.tit {
			padding-bottom: var(--sp10x);
		}
		.title {
			max-width: var(--sp45x);
		}

		.swiper-pagination {
			bottom: calc(-1 * var(--sp3x));
		}

		.swiper-container {
			.socIconItem {
				bottom: calc(100% + var(--sp3x));
			}

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						width: 48.4%;
						padding-top: 67.4%;
					}

					.info {
						margin-left: var(--sp2x);

						.pub-title {
							margin-bottom: var(--sp2x);
						}
					}
				}
			}
		}
	}
`

export default PublicationsStyle
