import styled from 'styled-components'

const GuestsStyle = styled.div.attrs((props) => ({}))`
    color:var(--backColor);
    position: relative;

    .bg {
        width: 150%;
        padding-top: 51.2%;
        transform: translateX(-16%);
        opacity: 0.5;
    }

    .Title {
        color: var(--textColor);
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }

    .imgimg {
        pointer-events: none;
        position: absolute;
        /* visibility: hidden; */
        transition: 0.5s ease-out opacity, all 0.2s ease-in-out;
        width: 20%;
        padding-top: 25.8%;
        transform: translate(-50%,-50%);
        opacity: 0;

        &.visible {
            opacity: 1;
        }

        &.grow-scale {
            opacity: 0;
            transition: 0.5s opacity ease-in-out;
        }

        img {
            object-fit: cover;
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}){
        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                .imgimg {
                    opacity: 1;

                    &:nth-child(1){
                        transition:var(--trTime) calc(3 * var(--trTime));
                    }

                    &:nth-child(2){
                        transition:var(--trTime) calc(2 * var(--trTime));
                    }

                    &:nth-child(3){
                        transition:var(--trTime) ;
                    }
                    &:nth-child(4){
                        transition:var(--trTime) calc(7 * var(--trTime));
                    }
                    &:nth-child(5){
                        transition:var(--trTime) calc(5 * var(--trTime));
                    }

                    &:nth-child(6){
                        transition:var(--trTime) calc(4 * var(--trTime));
                    }

                    &:nth-child(7){
                        transition:var(--trTime) calc(6 * var(--trTime));
                    }
                }
            }

            .imgimg {
                visibility: visible;
                width: 22.5%;
                padding-top: 29.2%;
                display: none;

                &:nth-child(1){
                    display: block;
                    top: 44%;
                    left: 21%;
                    z-index: 3;
                    transition:var(--trTime);
                }

                &:nth-child(2){
                    display:block;
                    top: 55%;
                    left: 10%;
                    z-index: 2;
                    transition:var(--trTime);
                }

                &:nth-child(3){
                    display:block;
                    top: 64%;
                    left: 17%;
                    z-index: 1;
                    transition:var(--trTime);
                }
                &:nth-child(4){
                    display:block;
                    top: 37%;
                    right: -6%;
                    z-index: 7;
                    transition:var(--trTime);
                }
                &:nth-child(5){
                    display:block;
                    top: 42%;
                    right: 4%;
                    z-index: 5;
                    transition:var(--trTime);
                }

                &:nth-child(6){
                    display:block;
                    top: 54%;
                    right: -9%;
                    z-index: 4;
                    transition:var(--trTime);
                }

                &:nth-child(7){
                    display:block;
                    top: 62%;
                    right: 0%;
                    z-index: 6;
                    transition:var(--trTime);
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        .bg {
            width: 283%;
            padding-top: 97.4%;
            transform: translateX(-32.5%);
        }

        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                .imgimg {
                    opacity: 1;

                    &:nth-child(1){
                        transition:var(--trTime) calc(3 * var(--trTime));
                    }

                    &:nth-child(2){
                        transition:var(--trTime) calc(2 * var(--trTime));
                    }

                    &:nth-child(3){
                        transition:var(--trTime) ;
                    }
                    &:nth-child(4){
                        transition:var(--trTime) calc(7 * var(--trTime));
                    }
                    &:nth-child(5){
                        transition:var(--trTime) calc(5 * var(--trTime));
                    }

                    &:nth-child(6){
                        transition:var(--trTime) calc(4 * var(--trTime));
                    }

                    &:nth-child(7){
                        transition:var(--trTime) calc(6 * var(--trTime));
                    }
                }
            }

            .imgimg {
                visibility: visible;
                width: 26.5%;
                padding-top: 34%;
                transform: translate(0,0);
                display: none;
                
                &:nth-child(1){
                    display: block;
                    top: 30%;
                    left: 1%;
                    z-index: 3;
                }

                &:nth-child(2){
                    display:block;
                    top: 45%;
                    left: 5%;
                    z-index: 1;
                }

                &:nth-child(3){
                    display:block;
                    top: 59%;
                    left: -3%;
                    z-index: 2;
                }

                &:nth-child(4){
                    display:block;
                    top: 17%;
                    right: 10%;
                    z-index: 7;
                }
                &:nth-child(5){
                    display:block;
                    top: 30%;
                    right: 1%;
                    z-index: 5;
                }

                &:nth-child(6){
                    display:block;
                    top: 52%;
                    right: 1%;
                    z-index: 4;
                }

                &:nth-child(7){
                    display:block;
                    top: 70%;
                    right: 12%;
                    z-index: 6;
                }
            }
        }
    }
`
export default GuestsStyle
