import styled from 'styled-components'

const KaleidoscopeStyle = styled.div`
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    canvas {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

export default KaleidoscopeStyle