import React, { memo as Memo, useRef, useEffect, useCallback } from 'react'

import { isWebPSupport } from 'helpers'

import KaleidoscopeStyle from './style'

const KaleidoscopeModule = Memo(() => {

    //! Refs
    const contRef = useRef()
    const canvasRef = useRef()
    const anim = useRef()

    //! Options
    const options = useRef({
        HALF_PI: Math.PI / 2,
        TWO_PI: Math.PI * 2,
        offsetRotation: 0,
        offsetScale: 1.0,
        offsetX: 0.0,
        offsetY: 0.0,
        radius: 1400,
        slices: 20,
        zoom: 1.5,
        ease: 0.004
    })

    //! Coordinates
    const coords = useRef({
        tx: options.current.offsetX,
        ty: options.current.offsetY,
        tr: options.current.offsetRotation
    })

    //! Animation Init
    useEffect(() => {
        const context = canvasRef?.current?.getContext('2d');
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        options.current.radius = window.innerWidth * 0.75

        const image = new Image();
        image.onload = () => {
            options.current = { ...options.current, image }
            draw()
            anim.current = requestAnimationFrame(update)
        };

        image.src = `/images/kaleidoscope${isWebPSupport() ? '.webp' : '.jpg'}`;

        return () => {
            cancelAnimationFrame(anim.current)
        }
    }, [])

    //! Did Mount
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMoved, false);

        return () => {
            window.removeEventListener('mousemove', onMouseMoved, false);
        }
    }, [coords.current])

    //! Mouse Move
    const onMouseMoved = useCallback((event) => {
        const cont = contRef.current.getBoundingClientRect()

        if (Math.abs(cont.y) < cont.height) {
            let cx, cy, dx, dy, hx, hy;
            cx = window.innerWidth / 2;
            cy = window.innerHeight / 2;
            dx = event.pageX / window.innerWidth;
            dy = event.pageY / window.innerHeight;
            hx = dx - 0.5;
            hy = dy - 0.5;
            coords.current.tx = hx * options.current.radius * -2;
            coords.current.ty = hy * options.current.radius * 2;
            coords.current.tr = Math.atan2(hy, hx);
        }
    }, [coords.current])

    //! Draw Animation
    const draw = useCallback(() => {
        if (canvasRef?.current) {
            const context = canvasRef.current.getContext('2d')
            let cx, i, index, ref, results, scale, step;
            canvasRef.current.width = canvasRef.current.height = options.current.radius * 2;
            context.fillStyle = context.createPattern(options.current.image, 'repeat');
            scale = options.current.zoom * (options.current.radius / Math.min(options.current.image.width, options.current.image.height));
            step = options.current.TWO_PI / options.current.slices;
            cx = options.current.image.width / 2;
            results = [];
            for (index = i = 0, ref = options.current.slices; 0 <= ref ? i <= ref : i >= ref; index = 0 <= ref ? ++i : --i) {
                context.save();
                context.translate(options.current.radius, options.current.radius);
                context.rotate(index * step);
                context.beginPath();
                context.moveTo(-0.5, -0.5);
                context.arc(0, 0, options.current.radius, step * -0.51, step * 0.51);
                context.lineTo(0.5, 0.5);
                context.closePath();
                context.rotate(options.current.HALF_PI);
                context.scale(scale, scale);
                context.scale([-1, 1][index % 2], 1);
                context.translate(options.current.offsetX - cx, options.current.offsetY);
                context.rotate(options.current.offsetRotation);
                context.scale(options.current.offsetScale, options.current.offsetScale);
                context.fill();
                results.push(context.restore());
            }
        }

    }, [options.current])

    //! Update Animation
    const update = useCallback(() => {
        const delta = coords.current.tr - options.current.offsetRotation;
        const theta = Math.atan2(Math.sin(delta), Math.cos(delta));

        options.current.offsetX += (coords.current.tx - options.current.offsetX) * options.current.ease;
        options.current.offsetY += (coords.current.ty - options.current.offsetY) * options.current.ease;
        options.current.offsetRotation += (theta - options.current.offsetRotation) * options.current.ease;
        draw();

        requestAnimationFrame(update)

        return () => {
            cancelAnimationFrame(anim.current)
        }
    }, [options.current, coords.current])

    return (
        <KaleidoscopeStyle ref={contRef} >
            <canvas ref={canvasRef} />
        </KaleidoscopeStyle>
    )
})

export default KaleidoscopeModule