import React, { memo as Memo, useEffect, useRef, useState, useMemo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Config
import { config } from 'helpers'

//* Components
import { Text, AnimationWrapp, SingleEvent, Container } from 'components/common'

const EventsSection = Memo(({ selectedLang, data }) => {
    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const titleRef = useRef()

    useEffect(() => {
        if (
            data && titleRef?.current
        ) {
            setSecObj([
                titleRef.current
            ])
        }
    }, [titleRef, data, selectedLang])


    const storeEvents = useMemo(() => {
        return Object.values(data).map((i, k) => {
            return (
                <SingleEvent
                    key={k}
                    url={config.routes.singleEvents.slug + "/" + i.slug}
                    title={i.title}
                    date={i.date}
                    category={i.category}
                    duration={i.duration}
                    entrance={i.entrance}
                    image={i.featured_image}
                />
            )
        })
    }, [data])

    return data.length != 0 &&
        <AnimationWrapp state={secObj}>
            <Container>
                <div className="FlexBox">
                    <Text className={'singleTitle h3 GHEAMariamBold uppercase margBottomTitle'} text={"Events"} ref={titleRef} />
                </div>
            </Container>
            {storeEvents}
        </AnimationWrapp>
})

export default withLanguageContext(EventsSection, ['selectedLang'])
