import styled from 'styled-components'


const HeroSectionAboutStyle = styled.div.attrs(props => ({ className: props.className }))`
    .bg-img {
        position: absolute;
        top: 0;
        left: 50%;
        width: 121%;
        z-index: -1;
        padding-top: 82.2%;
        transform: translateX(-50%);
        
        & > span {
            overflow: visible !important;
        }
    }

    .heading {
        align-self: center;
        min-width: var(--sp115x);

        .est {
            align-self: flex-end;
        }
    }

    .texts {
        max-width: var(--sp98x);
        margin-top:var(--sp82x);
        margin-right:calc(var(--sp32x) - var(--containerPadding)) ;
        align-self: flex-end;

        .overflowHidden {
            &:nth-child(2) {
                margin:var(--sp4x) 0;
            }
        }
    }

    .quote-block {
        margin-top: var(--containerMarginBottom);
        width: 100%;

        .autor {
            margin-top:var(--sp9x);

            i {
                line-height: 1;
                animation: rotate 6s infinite linear;
            }

            @keyframes rotate {
                0% {
                    transform:rotate(0)
                }

                100% {
                    transform:rotate(360deg)
                }
            }
            
            .name {
                margin-left:var(--sp3x)
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
        .heading {
            min-width: var(--sp101x);
        }

        .texts {
            max-width: var(--sp72x);
            margin-top:var(--sp59x);
            margin-right:calc(var(--sp28x) - var(--containerPadding)) ;
        }

        .quote-block {
            .autor {
                margin-top:var(--sp8x);
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
        .heading {
            min-width: var(--sp73x);
        }

        .texts {
            max-width: var(--sp66x);
            margin-top:var(--sp56x);
            margin-right:calc(var(--sp22x) - var(--containerPadding)) ;
        }

        .quote-block {
            .autor {
                margin-top:var(--sp7x);
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
        .heading {
            min-width: var(--sp68x);
        }

        .texts {
            max-width: var(--sp58x);
            margin-top:var(--sp54x);
            margin-right:calc(var(--sp20x) - var(--containerPadding)) ;
        }

        .quote-block {
            .autor {
                margin-top:var(--sp5x);
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
                
        section {
            overflow: hidden;
        }

        .heading {
            min-width: var(--sp54x);
        }

        .texts {
            max-width: var(--sp58x);
            margin-top:var(--sp45x);
            margin-right:calc(var(--sp17x) - var(--containerPadding)) ;

            @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
                margin-right:calc(var(--sp5x) - var(--containerPadding)) ;
            }
        }

        .quote-block {
            .autor {
                margin-top:var(--sp3x);

                .name {
                    margin-left:var(--sp2x);
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        section {
            overflow: hidden;
        }

        .bg-img {
            top: 0.5%;
            width: 255%;
            padding-top: 133%;
        }

        .heading {
            min-width: unset;
        }

        .texts {
            max-width:unset;
            margin-top:var(--sp43x);
            margin-right:0 ;
        }

        .quote-block {
            .autor {
                margin-top:var(--sp3x);

                .name {
                    margin-left:var(--sp2x);
                }
            }
        }
    }
`

export default HeroSectionAboutStyle