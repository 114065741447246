import styled from 'styled-components'

const StepInToParajanovStyle = styled.div`
	min-height: calc(100vw / 1.6);
	background-color: #272926;

	.imageCont {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:after {
			content: '';
			position: absolute;
			top: 15%;
			bottom: 5%;
			left: 5%;
			right: 5%;
			box-shadow: 10px 40px 40px 5px rgb(0, 0, 0, 38%);
		}
	}

	svg {
		fill: none;
		width: 90%;
		height: 40%;
		position: absolute;
		top: 0;
		left: 5%;
		.step1 {
			stroke: var(--textColor);
		}
	}

	.galleryWrap1 {
		padding: 10.2% 6.6%;
		top: 7%;
		left: 5%;
		.imageCont {
			height: 88%;
		}

		svg {
			left: 2.5%;
			width: 96%;
			height: 50%;
		}
	}
	.galleryWrap2 {
		padding: 11.7% 12.6%;
		bottom: 17%;
		left: 11%;
		.imageCont {
			height: 81%;
		}
	}
	.galleryWrap3 {
		padding: 11.7% 8.7%;
		bottom: 9%;
		right: 25%;
		.imageCont {
			height: 80%;
		}
	}
	.galleryWrap4 {
		padding: 14.6% 10.3%;
		top: 8%;
		right: 3%;
		.imageCont {
			height: 82%;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		.galleryWrap2,
		.galleryWrap4 {
			transform: rotate(40deg);
			.imageCont {
				transform: translateY(-30%);
			}
		}

		.galleryWrap1,
		.galleryWrap3{
			transform: rotate(-40deg);
			.imageCont {
				transform: translateY(-30%);
			}
		}
	}
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.galleryWrap1,
		.galleryWrap2,
		.galleryWrap3,
		.galleryWrap4 {
			position: absolute;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		min-height: var(--sp132x);

		.galleryWrap1 {
			padding: 14.2% 9%;
			top: 24%;
			left: 4%;
		}
		.galleryWrap2 {
			padding: 16.5% 17.5%;
			left: 4%;
			bottom: 19%;
		}
		.galleryWrap3 {
			padding: 16.5% 12%;
			right: 24%;
		}
		.galleryWrap4 {
			top: 35%;
			padding: 21% 14.3%;
		}
		
		svg {
			.step4 {
				stroke: var(--textColor);
			}
			.step1 {
				stroke: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		min-height: 100%;

		.galleryWrap1,
		.galleryWrap2,
		.galleryWrap3,
		.galleryWrap4 {
			position: relative;
			padding:0;
			width: 50%;
			top: 0 !important;
			left: 0 !important;
			svg {
				height: 40%;
			}
		}

		svg {
			.step4 {
				stroke: var(--textColor);
			}
			.step1 {
				stroke: none;
			}
		}

		.galleryWrapp {
			width: 100%;
			margin-top: var(--sp7x);
		}

		.gallery1 {
			padding: 55% 36%;
		}
		.gallery2 {
			padding: 51% 54%;
			margin-top: calc(-1 * var(--sp2x));
		}
		.gallery3 {
			padding: 51% 37%;
			margin-top: var(--sp25x);
		}
		.gallery4 {
			padding: 64% 44.5%;
			margin-top: var(--sp16x);
		}

		.gallery1,
		.gallery2,
		.gallery3,
		.gallery4 {
			position: relative;
			display: inline-block;
		}

		.galleryWrap1 {
			text-align:center;
			.gallery1{
				width: 71%;
			}
		}
		.galleryWrap2 {
			.imageCont{
				width: 100%;
			}
		}
		.galleryWrap3 {
			text-align:right;
			right: 24%; 
		}
		.galleryWrap4 {
			top: 35%;
			text-align:right;
			.gallery4{
				width: 71%;
			}
		}
	}
`
export default StepInToParajanovStyle
