import styled from 'styled-components'

const NewsStyle = styled.div`
	--iconSize: var(--sp10x);
	--iconStartSize: var(--sp10x);
	--linkWrapTopPadd: var(--sp16x);
	--linkWrapLeftPadd: var(--sp8x);
	--linkWrapBotPadd: var(--sp5x);
	--titleMargBot: var(--sp4x);
	--descriptionMargBot: var(--sp9x);
	--itemWrapMargRight: var(--sp4x);
	--itemWrap: calc(33.333% - ( var(--itemWrapMargRight) * 2));

	overflow: hidden;

	.newsItemsWrap {
		align-items: stretch;
		margin-right:calc( -1 * var(--itemWrapMargRight));
		margin-left:calc( -1 * var(--itemWrapMargRight));
		width: auto;

		.itemWrap {
			width: var(--itemWrap);
			margin-right:var(--itemWrapMargRight);
			margin-left:var(--itemWrapMargRight);

			.otherElementWrapp {
				position: absolute;
				top: 0;
				left: calc(100% + (var(--linkWrapLeftPadd) / 2));
				width: calc(72% - var(--linkWrapLeftPadd));
				height: 50%;
				transform: translateY(-24%);
				opacity: 0;
				.otherElement {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 90%;
					transform: translateX(-50%);
					i {
						margin-top: -30%;
						font-size: var(--iconStartSize);
						transform-origin: center;
						display: inline-block;
						animation: iconRotateAnim 4s linear infinite;
					}
					svg {
						position: absolute;
						top: 0;
						left: 0;
						fill: var(--textColor);
					}
				}
			}
			&:last-child {
				.otherElementWrapp {
					display: none;
				}
			}

			.linkWrap {
				width: 100%;
				height: 100%;
				border: 1px solid var(--textColor);
				border-top-right-radius: 60%;
				border-top-left-radius: 60%;
				opacity: 0;
				padding: var(--linkWrapTopPadd) var(--linkWrapLeftPadd) var(--linkWrapBotPadd);
				.iconWrap {
					opacity: 0;
					margin-bottom: var(--linkWrapTopPadd);
					transition: all var(--trTime) ease-in-out;
					i {
						display: inline-block;
						font-size: var(--iconSize);
					}
				}

				.GHEAMariamBold {
					margin-bottom: var(--titleMargBot);
				}

				.description {
					text-overflow: ellipsis;
					display: -moz-box;
					-moz-box-orient: vertical;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					line-clamp: 4;
					box-orient: vertical;
				}
				.date{
					margin-top: var(--descriptionMargBot);
				}
				&:hover {
					.iconWrap {
						opacity: 1;
					}
				}
			}
		}
	}

	@keyframes iconRotateAnim {
		0% {
			transform: rotate(-30deg);
		}

        20% {
            transform: rotate(30deg);
        }
        35% {
            transform: rotate(20deg);
        }
        50% {
            transform: rotate(32deg);
        }


        70% {
            transform: rotate(-30deg);
        }
        85% {
            transform: rotate(-20deg);
        }
		100% {
			transform: rotate(-30deg);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--iconSize: var(--sp7-5x);
		--iconStartSize: var(--sp6-5x);
		--linkWrapTopPadd: var(--sp11x);
		--linkWrapLeftPadd: var(--sp6x);
		--linkWrapBotPadd: var(--sp4x);
		--titleMargBot: var(--sp3x);
		--descriptionMargBot: var(--sp7x);
		--itemWrapMargRight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--iconSize: var(--sp6x);
		--iconStartSize: var(--sp5x);
		--linkWrapTopPadd: var(--sp9x);
		--linkWrapLeftPadd: var(--sp5x);
		--linkWrapBotPadd: var(--sp3x);
		--titleMargBot: var(--sp3x);
		--descriptionMargBot: var(--sp3-5x);
		--itemWrapMargRight: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--iconSize: var(--sp5x);
		--iconStartSize: var(--sp4x);
		--linkWrapTopPadd: var(--sp9x);
		--linkWrapLeftPadd: var(--sp4x);
		--linkWrapBotPadd: var(--sp3x);
		--titleMargBot: var(--sp2x);
		--descriptionMargBot: var(--sp7x);
		--itemWrapMargRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--iconSize: var(--sp5x);
		--iconStartSize: var(--sp5x);
		--linkWrapTopPadd: var(--sp6x);
		--linkWrapLeftPadd: var(--sp4x);
		--linkWrapBotPadd: var(--sp3x);
		--titleMargBot: var(--sp2x);
		--descriptionMargBot: var(--sp7x);
		--itemWrapMargRight: var(--sp2x);
		--itemWrap: calc(50% - ( var(--itemWrapMargRight) * 2));

		.newsItemsWrap {
			align-items: stretch;

			.itemWrap {
				&:nth-child(2) {
					.otherElementWrapp {
						display: none;
					}
				}
				.otherElementWrapp {
					.otherElement {
						height: 85%;
						i {
							margin-top: -25%;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {

		--linkWrapTopPadd: var(--sp4x);

	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--iconSize: var(--sp5x);
		--iconStartSize: var(--sp4x);
		--linkWrapTopPadd: var(--sp9x);
		--linkWrapLeftPadd: var(--sp4x);
		--linkWrapBotPadd: var(--sp3x);
		--titleMargBot: var(--sp2x);
		--descriptionMargBot: var(--sp7x);
		--itemWrapMargRight:0;
		--itemWrap: 100%;

		.newsItemsWrap {
			.itemWrap {
				&:nth-child(2) {
					display: none;
				}
				.otherElementWrapp {
					display: none;
				}
				.linkWrap {
					.iconWrap {
						opacity: 1;
					}
				}
			}
		}
	}
`
export default NewsStyle
