import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Text } from 'components/common'

const SingleWork = Memo(({ winHeight, ...props }) => {
    const ref = useRef();
    const [active, setActive] = useState('')

    useEffect(() => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.9) {
            setTimeout(() => {
                setActive('active')
            }, 300);
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.9) {
            setActive('active')
        } else {
            setActive('')
        }
    }

    return (
        <div ref={ref} className={`single-work FlexBox ${active}`} onMouseEnter={props.onMouseEnter}>
            <Text tag={'p'} className={'year pM NotoSansRegular'} text={props.year} />
            <Text tag={'p'} className={'city pM NotoSansRegular'} text={props.city} />
            <Text tag={'p'} className={'ex pM NotoSansRegular'} text={props.title} />
            <Text tag={'p'} className={'gallery pM NotoSansRegular'} text={props.gallery} />
        </div>
    )
})

export default withUIContext(SingleWork, ['winHeight'])