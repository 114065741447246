import React, { memo as Memo, useCallback, useEffect, useRef, useState } from 'react'
import { withLanguageContext } from 'context'
import { TimelineLite } from 'gsap'

//* Config
import { config } from 'helpers'

//* Components
import { Text, Container, CustomLink, AnimationWrapp, CircleContainer } from 'components/common'

//* Style
import FilmStyle from './style'

const FilmSection = Memo(({ selectedLang }) => {
    //! States
    const [secObj, setSecObj] = useState()
    const [secTopObj, setSecTopObj] = useState()
    const [startAnimation, setStartAnimation] = useState(false)

    //! Refs
    const titleRef = useRef()
    const contRef = useRef()
    const videoRef = useRef()

    useEffect(() => {
        if (
            titleRef?.current && contRef?.current
        ) {
            setSecTopObj(contRef.current[0])
            setSecObj([
                titleRef.current.firstChild
            ])
        }
    }, [titleRef, contRef, selectedLang])

    useEffect(() => {
        const playPromise = videoRef.current.play()
        if (playPromise && playPromise !== undefined && startAnimation) {
            videoRef.current.play()
        }

        // const pausePromise = videoRef.current.pause()
        // if(pausePromise && pausePromise ==undefined && !startAnimation){
        // 	videoRef.current.pause()
        // }
    }, [videoRef, startAnimation])

    const StartAnim = useCallback((x) => {
        if (x && !startAnimation) {
            setStartAnimation(true)
        }
        else {
            setStartAnimation(false)
        }
    }, [])


    return (
        <AnimationWrapp state={secObj} sectionTop={secTopObj} sectionAnimationStart={e => StartAnim(e)} otherStartPoint={'top+=80% bottom+=90%'}>
            <CustomLink url={config.routes.films.path} id="VisitFilmes">
                <Container className='relativeWrapp overflowHidden containerPaddTopBottom' ref={contRef}>
                    <FilmStyle>
                        <div className="FlexBoxContainer alignCenter" ref={titleRef}>
                            <Text className={'singleTitle h1L GHEAMariamBold uppercase margBottomTitle'} text={"Films"} />
                        </div>

                        <CircleContainer startAnimation={startAnimation} >
                            <div className="textWrapLeft">
                                <Text tag="span" className={'pL NotoSansRegular uppercase'} text={"17Films"} />
                            </div>
                            <div className="videoWrapper relativeWrapp overflowHidden">
                                <video ref={videoRef} className="homepageFilms" width="100%" height="100%" playsInline autoPlay={false} muted loop={true}>
                                    <source src={'./videos/homepage_films_section.webm'} type="video/webm" />
                                    <source src={'./videos/homepage_films_section.mp4'} type="video/mp4" />
                                </video>
                            </div>
                            <div className="textWrapRight">
                                <Text tag="span" className={'pL NotoSansRegular uppercase'} text={"24Awards"} />
                            </div>
                        </CircleContainer>

                    </FilmStyle>
                </Container>
            </CustomLink>
        </AnimationWrapp>
    )
})

export default withLanguageContext(FilmSection, ['selectedLang'])
