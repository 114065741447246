import React, { memo as Memo, useState, useRef, useEffect, useMemo } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Config
import { config, randomIntFromInterval } from 'helpers'

//* Components
import { Text, Image, CustomLink } from 'components/common'

//* Styles
import SingleNewsStyle from './style'

const SingleNews = Memo(({ winHeight, ...props }) => {
    const ref = useRef();
    const [activeClass, setActiveClass] = useState('');

    useEffect(() => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.9) {
            setTimeout(() => {
                setActiveClass('active')
            }, 300);
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.9) {
            setActiveClass('active')
        } else {
            setActiveClass('')
        }
    }

    const storeLoadImg = useMemo(() => {
        return `/images/LoadImgs/${randomIntFromInterval(1, 11)}.png`
    }, [])

    return (
        <SingleNewsStyle ref={ref} className={`FlexBoxColumn alignMiddle ${props.className} ${activeClass}`}>
            <CustomLink className={`FlexBoxColumn alignMiddle`} url={config.routes.news.path + "/" + props.url}>
                <Text tag={'p'} className={'title p GHEAMariamRegular'} text={props.title} />
                <div className='images'>
                    <Image className="load-img" src={storeLoadImg} />
                    <Image className='image' src={props.image} />
                </div>
                <Text tag={'p'} className={'date pXS NotoSansRegular'} text={props.date} />
            </CustomLink>
        </SingleNewsStyle>
    )
})


export default withUIContext(SingleNews, ['winHeight'])