import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Text, AnimationWrapp, Button } from 'components/common'

//* Config
import { arrayGen, config } from 'helpers'


const SupportTopSecondSection = Memo(({ selectedLang, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const groupRef = useRef()

    useEffect(() => {
        if (
            groupRef?.current
        ) {
            setSecObj(
                arrayGen([groupRef.current.childNodes[0].firstChild, groupRef.current.childNodes[1].firstChild]))
        }

    }, [groupRef, selectedLang])

    return (
        <AnimationWrapp state={secObj} >
            <div className="SupportTopSection ">
                <Container className="containerPaddBottom">

                    <div className="subTitleWrapp " ref={groupRef}>
                        <div className="overflowHidden FlexBoxContainer alignCenter">
                            <Text className={'singleTitle h6 NotoSansRegular uppercase textAlignCenter'} text={props.description} />
                        </div>
                        <div className="overflowHidden FlexBoxContainer alignCenter">
                            <Button text={config.routes.supportUs.name} link={config.routes.donate.path} btnFrame={true} wrappClassName={"otherElementAnimation"} />
                        </div>
                    </div>

                </Container>
            </div>
        </AnimationWrapp>
    )
})

export default withLanguageContext(SupportTopSecondSection, ['selectedLang'])
