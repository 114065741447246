import styled from 'styled-components'

const HeroStyle = styled.div`
	--iconSize: var(--sp9x);
	--iconMargBottom: var(--sp4x);
	--imageSize: 23% 19%;
	--headerHeight:var(--sp25x);

	&.HeroInfoWrapp {
		min-height: calc(100vh - var(--sp28x));
		align-items: stretch;

		>.title {
			position: absolute;
			z-index: 12;
			top: 5%;
			left: 0;
			max-width: 33%;
		}
		.HeroInfoBottom {
			position: absolute;
			bottom:10vh;
			left: 0;
			right: 0;
			z-index: 12;
			.leftSection {
				max-width: 26%;
				i {
					display: block;
					margin-bottom: var(--iconMargBottom);
					font-size: var(--iconSize);
				}
			}
		}
		.ImageWrapp {
			.ImageSection {
				padding: var(--imageSize);
				overflow: hidden;
				&:after {
					content: '';
					position: absolute;
					top: -3px;
					left: -3px;
					right: -3px;
					bottom: -3px;
					z-index: 4;
					border: 10px solid var(--backColor);
				}

				.imageCont,
				.EyesWrapp,
				.GirlBoyWrapp,
				.BroochWrapp,
				.BroochLeft,
				.BroochRight,
				.EyesWrapp,
				.necklaceWrapp,
				.necklaceLine1,
				.necklaceLine2,
				.PapiWrapp,
				.roseVideo,
				.rose,
				video {
					position: absolute;
				}
				.Frame {
					position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 50%;
                    right: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    
					> span {
						z-index: 11;
					}
				}

				.backVideeo {
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}

				.GirlBoyWrapp {
					top: 10%;
					left: 9%;
					padding: 59% 46%;
					z-index: 1;
					.GirlBoy {
						top: 4%;
						left: 9%;
						padding: 64% 41%;
						z-index: 1;
					}

					.BroochWrapp {
						top: 3%;
						left: 16%;
						padding: 31% 36%;
						.BroochLeft {
							top: 49%;
							left: 4%;
							padding: 24% 16%;
							z-index: 1;
							.Brooch1-1 {
								top: 2%;
								left: 30%;
								padding: 28% 18%;
							}
							.Brooch1-2 {
								top: 40%;
								left: 32%;
								padding: 17% 13%;
							}
						}

						.BroochRight {
							top: 59%;
							right: 2%;
							padding: 25% 25%;
							z-index: 2;

							.Brooch2-1 {
								top: 22%;
								left: 11%;
								padding: 15% 10%;
							}
							.Brooch2-2 {
								top: 26%;
								right: 30%;
								padding: 34% 11%;
							}
							.Brooch2-3 {
								top: 39%;
								right: 48%;
								padding: 16% 12%;
							}
						}
					}

					.roseVideo {
						top: 38%;
						left: 43%;
						padding: 6% 6%;
						z-index: 2;
						.rose {
							top: 0%;
							left: 0%;
							width: 100%;
							height: 100%;
						}
					}

					.EyesWrapp {
						top: 33.5%;
						left: 34%;
						padding: 1.5% 9%;
						z-index: -1;
						transform: rotate(6deg);

						.EyeL,
						.EyeR {
							top: 0%;
							padding: 7%;
							z-index: 2;
						}
						.EyeR {
							right: 8%;
						}
						.EyeL {
							left: 12%;
						}

						.EyeBall-L {
							left: -9%;
						}
						.EyeBall-R {
							right: 1%;
						}

						.EyeBall-L,
						.EyeBall-R {
							top: -22%;
							padding: 11% 20%;
						}
					}

					.necklaceWrapp {
						bottom: 19%;
						left: 22%;
						padding: 18% 25%;
						z-index: 2;

						.necklace1 {
							top: 23%;
							left: 10%;
							padding: 8% 7%;
						}
						.necklace2 {
							top: 30%;
							left: 20%;
							padding: 11% 7%;
						}

						.necklaceLine1 {
							top: 45%;
							left: 29%;
							padding: 29% 8%;
							.necklace3-1 {
								top: 2%;
								left: 25%;
								padding: 19% 19%;
							}

							.necklace3-2 {
								top: 14%;
								left: -4%;
								padding: 40% 45%;
							}

							.necklace3-3 {
								top: 35%;
								left: 25%;
								padding: 17% 18%;
							}

							.necklace3-4 {
								top: 43%;
								left: -7%;
								padding: 40% 45%;
							}

							.necklace3-5 {
								top: 67%;
								left: 33%;
								padding: 19% 19%;
							}
						}
						.necklaceLine2 {
							top: 41%;
							left: 39%;
							padding: 29% 18%;
							.necklace4-1 {
								top: 0;
								left: -7%;
								padding: 33% 39%;
							}

							.necklace4-2 {
								top: 41%;
								left: 24%;
								padding: 28% 11%;
							}
						}
						.necklace5 {
							top: 21%;
							right: -2%;
							padding: 19% 24%;
						}
					}
				}

				.PapiWrapp {
					top: 36%;
					left: -12%;
					padding: 46% 36%;
					z-index: 1;

					.Papi {
						top: 0;
						left: 0;
						padding: 150% 50%;
					}

					.PapiHand {
						bottom: -70%;
						left: 45%;
						padding: 86% 48%;
						z-index: 3;
					}

					.Earing {
						top: 25%;
						left: 47.5%;
						padding: 4% 3%;
						z-index: 1;
					}

					.Helix {
						top: 48%;
						left: 32%;
						padding: 15.5% 16%;
						z-index: 2;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
			--iconSize: var(--sp7x);
			--iconMargBottom: var(--sp3x);
			--imageSize: 25% 20.5%;
			--headerHeight:var(--sp18x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXSMin}) {
	
			--iconSize: var(--sp4x);
			--heroInfoPaddingBottom: var(--sp3x);
			--headerHeight:10vh;

			&.HeroInfoWrapp {
				.HeroInfoBottom {
					bottom:initial ;
					top: calc(100vh - (var(--containerMarginTop) + var(--headerHeight)));
					transform: translateY(-120%);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
			--iconSize: var(--sp5x);
			--iconMargBottom: var(--sp3x);
			--imageSize: 25.5% 21%;
			--headerHeight:var(--sp12x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
			--iconSize: var(--sp4x);
			--iconMargBottom: var(--sp2x);
			--imageSize: 26% 21.5%;
			--headerHeight:var(--sp12x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
			--iconMargBottom: var(--spSize);
			--imageSize: 25.5% 21%;
			--headerHeight:10vh;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
			--iconMargBottom: var(--spSize);
			--imageSize: 43.5% 36%;
			--heroInfoPaddingBottom: var(--sp6x);
			--headerHeight:10vh;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
			--iconSize: var(--sp4x);
			--heroInfoPaddingBottom: var(--sp3x);

			&.HeroInfoWrapp {
				min-height:auto;
				.HeroInfoBottom {
					position: static;
					width: 100%;
					margin-top: var(--heroInfoPaddingBottom);
				}
				.leftSection {
					max-width: 35%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			.title {
				position: relative;
				max-width: 60%;
				margin-bottom: calc(-1 * var(--sp9-5x));
			}

			&.HeroInfoWrapp {
				.leftSection {
					max-width: 45%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--iconMargBottom: var(--spSize);
			--imageSize: 56% 46.5%;
			--heroInfoPaddingBottom: var(--sp2-5x);

			&.HeroInfoWrapp {
				.title {
					margin-bottom: var(--spSize);
				}
				.HeroInfoBottom {
					.leftSection {
						order: 2;
					}
					.title {
						max-width: 100%;
						width: 100%;
						text-align: right;
						order: 1;
						margin-bottom: var(--heroInfoPaddingBottom);
					}
				}
				.leftSection {
					max-width: 100%;
				}
			}

			.ImageSection {
				padding: 0 var(--imageSize);
				height: 103vw;
			}
		}
	}
`
export default HeroStyle
