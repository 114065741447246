import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Text, Title, Button, Container, AnimationWrapp } from 'components/common'

//* Config
import { config, arrayGen, arrayGenChild } from 'helpers'

//* Style
import HostStyle from './style'

const Host = Memo(({ selectedLang, hostContent, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const groupRef = useRef()
    const titleRef = useRef()
    const contRef = useRef()

    useEffect(() => {
        if (groupRef?.current) {
            let arr = arrayGenChild(titleRef.current[0])

            setSecObj([arrayGen([arr, groupRef.current.childNodes])])
        }
    }, [titleRef, groupRef, selectedLang])

    return (
        <AnimationWrapp state={secObj}>
            <HostStyle className='SupportUsWrapp FlexBox alignMiddle alignCenter relativeWrapp'>
                <Container className='overflowHidden containerPaddTopBottom' ref={contRef}>
                    <div className='SupportUsInfoWrapp FlexBoxContainer alignCenter'>
                        <Title
                            className={'justifyEnd '}
                            ref={titleRef}
                            title={{
                                1: [
                                    { type: 'big', name: 'hostYour', space: true },
                                    { type: 'min', name: 'event' },
                                ],
                                2: [{ type: 'icon' }, { type: 'big', name: 'atMuseum', space: true }],
                            }}
                        />
                        <div className='FlexBoxColumn alignCenter alignMiddle' ref={groupRef}>
                            <Text tag={'p'} className={'description pM NotoSansRegular'} text={hostContent} />
                            <Button
                                className='reverseColor'
                                text={config.routes.contact.name}
                                link={config.routes.contact.path}
                                btnAnimation={true}
                                wrappClassName={'otherElementAnimation'}
                            />
                        </div>
                    </div>
                </Container>
            </HostStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(Host, ['selectedLang'])
