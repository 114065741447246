import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext, withDataContext } from 'context'

//* Components
import { AnimationWrapp, FormItem, FormContainer, FormInput, LottieAnimation } from 'components/common'

//* Components
import { Button, Input } from 'antd'

//* Helpers
import { arrayGenChild } from 'helpers'

//* Styles
import FormStyle from 'styles/pages/FormStyle'

import BtnLottie from 'lottieAnimation/btnAnimation.json'


const ContactForm = Memo(({ selectedLang, donated, ...props }) => {


    //! States
    const [secObj, setSecObj] = useState()
    const [playState, setPlaySate] = useState('')

    const inputGroupRef = useRef()

    useEffect(() => {
        if (inputGroupRef?.current) {
            setSecObj([arrayGenChild(inputGroupRef.current.childNodes)])
        }
    }, [inputGroupRef, selectedLang])

    return (
        <AnimationWrapp state={secObj} >
            <FormStyle>
                <div className='contactFormWrapp'>
                    <FormContainer
                        layout={'vertical'}
                        onFinish={props.onClick}
                        initialValues={{ plan: true }}
                    >
                        <div className="FormRow FlexBoxContainer spaceBetween" ref={inputGroupRef}>

                            <div className='FormCol FormCol-50 overflowHidden'>
                                <FormItem className="otherElementAnimation" name={'name'} required={true}>
                                    <FormInput placeholder={props.translate('Name') + '*'} />
                                </FormItem>
                            </div>
                            <div className='FormCol FormCol-50 overflowHidden'>
                                <FormItem className="otherElementAnimation" name={'surname'} required={true}>
                                    <FormInput placeholder={props.translate('Surname') + '*'} />
                                </FormItem>
                            </div>

                            <div className='FormCol FormCol-50 overflowHidden'>
                                <FormItem className="otherElementAnimation" validationType={'email'} name={'email'} required={true}>
                                    <FormInput placeholder={props.translate('EmailAddress') + '*'} />
                                </FormItem>
                            </div>
                            <div className='FormCol FormCol-50 overflowHidden'>
                                <FormItem className="otherElementAnimation" validationType={'phone'} name={'phone'} required={true}>
                                    <FormInput disableKeys={['-', 'e']} type={'number'} placeholder={props.translate('PhoneNumber') + '*'} />
                                </FormItem>
                            </div>

                            <div className='FormCol overflowHidden'>
                                <FormItem className="otherElementAnimation" name={'OrganizationFondName'} required={false}>
                                    <FormInput placeholder={props.translate('OrganizationOrFondName')} />
                                </FormItem>
                            </div>


                            <div className='FormCol marginNone overflowHidden'>
                                <FormItem className="otherElementAnimation" name={'message'}>
                                    <Input.TextArea placeholder={props.translate('Message')} />
                                </FormItem>
                            </div>

                            <div className='btn-cont FlexBox justifyEnd overflowHidden'>
                                <Button htmlType={'Submit'} className={`btn otherElementAnimation NotoSansRegular btnAnimation pM uppercase`} onMouseEnter={() => setPlaySate(true)}
                                    onMouseOut={() => setPlaySate(false)}>
                                    <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                                    <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                                    {props.translate('Submit')}
                                </Button>
                            </div>
                        </div>
                    </FormContainer>
                </div>
            </FormStyle>
        </AnimationWrapp>
    )
})

export default withLanguageContext(withDataContext(ContactForm,
    ['donated']),
    ['selectedLang', 'translate'])