import React, { memo as Memo, useRef, useCallback, useEffect, useState, useMemo } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { AnimationWrapp, Image, Text, Icon, Container } from 'components/common'

import { gsap, Elastic } from 'gsap'

import HeroStyle from './style'

const HeroSection = Memo(({ soundOfOnSwitcher, selectedLang, heroText, winWidth, screenSizes, preloader, isSafari, ...props }) => {
	//! States
	const [secObj, setSecObj] = useState()
	const [isMount, setIsMount] = useState(false)

	//! Refs
	const contRef = useRef()
	const papiWrappRef = useRef()
	const papiHandRef = useRef()
	const girlBoyRef = useRef()
	const eyesRef = useRef([])
	const earing = useRef()
	const helix = useRef()
	const broochLeftRef = useRef()
	const broochLeft1Ref = useRef()
	const broochRight1Ref = useRef()
	const broochRight2Ref = useRef()
	const broochRight3Ref = useRef()
	const necklace1 = useRef()
	const necklace2 = useRef()
	const necklaceLine1 = useRef()
	const necklace1Line1 = useRef()
	const necklace2Line1 = useRef()
	const necklace3Line1 = useRef()
	const necklace4Line1 = useRef()
	const necklaceLine2 = useRef()
	const necklace1Line2 = useRef()
	const necklace5 = useRef()

	const titleTopRef = useRef()
	const descriptionRef = useRef()
	const titleBottomRef = useRef()

	//! Animation
	useEffect(() => {
		window.addEventListener('mousemove', onMouseMove)
		return () => {
			window.removeEventListener('mousemove', onMouseMove)
		}
	}, [winWidth])

	const onMouseMove = useCallback(
		(e) => {
			const cont = contRef.current.getBoundingClientRect()

			if (Math.abs(cont.y) < cont.height && winWidth >= 1024) {
				const W = window.innerWidth / 2
				const H = window.innerHeight / 2
				const X = e.screenX
				const Y = e.screenY
				const percentX = (X - W) / W
				const percentY = (Y - H) / H

				gsap.fromTo(
					helix.current,
					{ y: -10 },
					{ duration: 1, y: 10, repeat: -1, yoyo: true, ease: 'M0,0,C0.392,0,0.448,0.286,0.504,0.536,0.534,0.674,0.61,0.976,1,1' }
				)

				//! Papi
				gsap.to(papiWrappRef.current, { x: percentX * -20, y: percentY * -30 })
				gsap.to(eyesRef.current, { x: (percentX - 1) * 7, y: (percentY - 0.4) * 6 })
				gsap.to(papiHandRef.current, { rotation: (percentX - 0.5) * -5, transformOrigin: '30% 30%' })
				gsap.fromTo(
					earing.current,
					{ rotation: `${percentX * 20}` },
					{ duration: 2, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)

				//! Girl-Boy
				gsap.to(girlBoyRef.current, { x: percentX * -30, y: percentY * -60 })

				gsap.fromTo(
					broochLeftRef.current,
					{ rotation: (percentX + 0.5) * 25 },
					{ duration: 1.5, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					broochLeft1Ref.current,
					{ rotation: percentX * -35 },
					{ duration: 2.3, repeat: 2, delay: 0.2, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)

				gsap.fromTo(
					broochRight1Ref.current,
					{ rotation: percentX * -25 },
					{ duration: 2, rotation: `0`, transformOrigin: 'top right', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					broochRight2Ref.current,
					{ rotation: percentX * -15 },
					{ duration: 4, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					broochRight3Ref.current,
					{ rotation: percentX * -30 },
					{ duration: 1.5, rotation: `0`, transformOrigin: 'top right', ease: Elastic.easeOut }
				)

				gsap.fromTo(
					necklace1.current,
					{ rotation: percentX * -18 },
					{ duration: 2, rotation: `0`, transformOrigin: 'top right', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					necklace2.current,
					{ rotation: percentX * -15 },
					{ duration: 1.5, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)

				gsap.fromTo(
					[necklaceLine1.current, necklace3Line1.current],
					{ rotation: percentX * -13 },
					{ duration: 1.5, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					[necklace4Line1.current, necklace5.current],
					{ rotation: percentX * -19 },
					{ duration: 2, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)

				gsap.fromTo(
					[necklace1Line1.current, necklaceLine2.current],
					{ rotation: percentX * 18 },
					{ duration: 1.8, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)
				gsap.fromTo(
					[necklace2Line1.current, necklace1Line2.current],
					{ rotation: percentX * 16 },
					{ duration: 2.3, rotation: `0`, transformOrigin: 'top center', ease: Elastic.easeOut }
				)
			}
		},
		[winWidth]
	)

	const images = useMemo(() => {
		if (typeof window !== undefined) {
			return winWidth >= 1024 ? (
				<div className='ImageSection relativeWrapp'>
					<Image priority={'low'} className='Frame' src={'/images/HeroSection/Frame.png'} alt={'Frame'} />

					<div ref={girlBoyRef} className='GirlBoyWrapp'>
						<Image className='GirlBoy' src={'/images/HeroSection/Girl-Boy.png'} alt={'Girl-Boy'} />

						{isMount && !isSafari && (
							<div className='roseVideo'>
								<video className='rose' width='100%' height='100%' autoPlay muted loop={true}>
									<source src={'/images/HeroSection/rose.webm'} />
								</video>
							</div>
						)}

						<div className='BroochWrapp'>
							<div ref={broochLeftRef} className='BroochLeft'>
								<Image className='Brooch1-1' src={'/images/HeroSection/Brooch1-1.png'} alt={'Brooch1-1'} />
								<Image ref={broochLeft1Ref} className='Brooch1-2' src={'/images/HeroSection/Brooch1-2.png'} alt={'Brooch1-2'} />
							</div>
							<div className='BroochRight'>
								<Image ref={broochRight1Ref} className='Brooch2-1' src={'/images/HeroSection/Brooch2-1.png'} alt={'Brooch2-1'} />
								<Image ref={broochRight2Ref} className='Brooch2-2' src={'/images/HeroSection/Brooch3-1.png'} alt={'Brooch3-1'} />
								<Image ref={broochRight3Ref} className='Brooch2-3' src={'/images/HeroSection/Brooch3-2.png'} alt={'Brooch3-2'} />
							</div>
						</div>

						<div className='EyesWrapp'>
							<Image className='EyeBall-L' src={'/images/HeroSection/EyeBall-L.png'} alt={'EyeBall-L'} />
							<Image className='EyeBall-R' src={'/images/HeroSection/EyeBall-R.png'} alt={'EyeBall-R'} />

							<Image ref={(ref) => (eyesRef.current[0] = ref)} className='EyeL' src={'/images/HeroSection/EyeL.png'} alt={'EyeL'} />
							<Image ref={(ref) => (eyesRef.current[1] = ref)} className='EyeR' src={'/images/HeroSection/EyeR.png'} alt={'EyeR'} />
						</div>

						<div className='necklaceWrapp'>
							<Image ref={necklace1} className='necklace1' src={'/images/HeroSection/necklace1.png'} alt={'necklace1'} />
							<Image ref={necklace2} className='necklace2' src={'/images/HeroSection/necklace2.png'} alt={'necklace2'} />

							<div ref={necklaceLine1} className='necklaceLine1'>
								<Image className='necklace3-1' src={'/images/HeroSection/necklace3-1.png'} alt={'necklace3-1'} />
								<Image ref={necklace1Line1} className='necklace3-2' src={'/images/HeroSection/necklace3-2.png'} alt={'necklace3-2'} />
								<Image ref={necklace2Line1} className='necklace3-3' src={'/images/HeroSection/necklace3-3.png'} alt={'necklace3-3'} />
								<Image ref={necklace3Line1} className='necklace3-4' src={'/images/HeroSection/necklace3-4.png'} alt={'necklace3-4'} />
								<Image ref={necklace4Line1} className='necklace3-5' src={'/images/HeroSection/necklace3-5.png'} alt={'necklace3-5'} />
							</div>
							<div ref={necklaceLine2} className='necklaceLine2'>
								<Image className='necklace4-1' src={'/images/HeroSection/necklace4-1.png'} alt={'necklace4-1'} />
								<Image ref={necklace1Line2} className='necklace4-2' src={'/images/HeroSection/necklace4-2.png'} alt={'necklace4-2'} />
							</div>

							<Image ref={necklace5} className='necklace5' src={'/images/HeroSection/necklace5.png'} alt={'necklace5'} />
						</div>
					</div>

					<div ref={papiWrappRef} className='PapiWrapp'>
						<Image className='Papi' src={'/images/HeroSection/Papi.png'} alt={'Papi'} />
						<Image ref={papiHandRef} className='PapiHand' src={'/images/HeroSection/Papi-Hand.png'} alt={'Papi-Hand'} />
						<Image ref={earing} className='Earing' src={'/images/HeroSection/Earing.png'} alt={'Earing'} />
						<Image ref={helix} className='Helix' src={'/images/HeroSection/Helix.png'} alt={'Helix'} />
					</div>

					<video className='backVideeo' width='100%' height='100%' autoPlay={true} loop={true}>
						<source src={'./videos/HeroSection.webm'} type='video/webm' />
						<source src={'./videos/HeroSection.mp4'} type='video/mp4' />
					</video>
				</div>
			) : (
				<div className='ImageSection relativeWrapp'>
					{isMount && <Image className='Frame' src={'/images/HeroSection/MobImage.jpg'} alt={'Frame'} />}
				</div>
			)
		}
	}, [winWidth, isMount])

	useEffect(() => {
		setIsMount(!preloader)
		if (titleTopRef?.current && descriptionRef?.current && titleBottomRef?.current) {
			setSecObj([
				titleTopRef.current.childNodes,
				titleBottomRef.current.firstChild,
				[descriptionRef.current.childNodes[0].firstChild, descriptionRef.current.childNodes[1].firstChild],
			])
		}
	}, [titleTopRef, descriptionRef, titleBottomRef, preloader, selectedLang])

	return (
		<AnimationWrapp state={secObj}>
			<Container className='containerPaddBottom containerPaddTop' onMouseMove={onMouseMove}>
				<HeroStyle ref={contRef} className='HeroInfoWrapp relativeWrapp'>
					<Text tag={'stringToLetters'} className={'title h1 GHEAMariamBold uppercase'} text={'SergeyParajanov'} ref={titleTopRef} />

					<div className='ImageWrapp FlexBoxContainer alignCenter'>{images}</div>

					<div className='HeroInfoBottom FlexBoxContainer alignMiddle spaceBetween'>
						<div className='leftSection' ref={descriptionRef}>
							<div className='overflowHidden'>
								<Icon className={'icon-art3 description'} />
							</div>
							<div className='overflowHidden'>
								<Text tag={'p'} className={'description pL GHEAMariamBold uppercase'} text={heroText} />
							</div>
						</div>

						<Text tag={'stringToLetters'} className={'title h2 NotoSansRegular uppercase'} text={'Museum'} ref={titleBottomRef} />
					</div>
				</HeroStyle>
			</Container>
		</AnimationWrapp>
	)
})

export default withLanguageContext(withUIContext(HeroSection, ['winWidth', 'screenSizes', 'preloader', 'checkLoadedImage']), ['selectedLang'])
